import * as Sentry from '@sentry/react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from '../components/ui/use-toast.js';
import { useAuth } from '../providers/auth/AuthProvider.js';

interface ConfluenceSpace {
  id: string;
  key: string;
  name: string;
  type: string;
  status: string;
  _links: {
    webui: string;
  };
}

interface InstallResponse {
  message: string;
  redirectUrl: string;
}

interface ConnectionStatus {
  status: 'connected' | string;
}

interface PagesResponse {
  pages: ConfluencePage[];
  _links: {
    next?: string;
    base: string;
  };
}

interface SpacesResponse {
  spaces: ConfluenceSpace[];
  _links: {
    next?: string;
    base: string;
  };
}

interface ConfluencePage {
  id: string;
  title: string;
  status: string;
}

interface ExportToConfluenceParams {
  documentId: string;
  spaceId: string;
  parentPageId: string;
  newPageTitle?: string;
}

export const useConfluenceManagement = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const [installProgress] = useState(0);

  const getHeaders = () => {
    return {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  };
  const installConfluenceMutation = useMutation<InstallResponse, Error>({
    mutationFn: async () => {
      const response = await fetch(`${apiBaseUrl}/confluence/install`, {
        method: 'POST',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to install Confluence');
      }

      return response.json();
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['confluenceConnectionStatus'],
      });
      // Open the Atlassian authorization page in a new tab
      window.open(data.redirectUrl, '_blank', 'noopener,noreferrer');
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const getConnectionStatusQuery = useQuery<ConnectionStatus, Error>({
    queryKey: ['confluenceConnectionStatus'],
    queryFn: async () => {
      const response = await fetch(
        `${apiBaseUrl}/confluence/connectionStatus`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      );

      if (!response.ok) {
        Sentry.captureException('Failed to get connection status');
        throw new Error('Failed to get connection status');
      }
      const data = await response.json();
      return data;
    },
  });

  const getSpacesQuery = () => {
    return useInfiniteQuery<SpacesResponse, Error>({
      queryKey: ['confluenceSpaces'],
      queryFn: async ({ pageParam = null }) => {
        const url = new URL(`${apiBaseUrl}/confluence/spaces`);
        url.searchParams.append('limit', '10');
        if (pageParam) {
          url.searchParams.append('cursor', pageParam as string);
        }

        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: getHeaders(),
        });

        if (!response.ok) {
          Sentry.captureException('Failed to fetch Confluence spaces');
          throw new Error('Failed to fetch Confluence spaces');
        }
        return response.json();
      },
      getNextPageParam: (lastPage) => {
        if (lastPage._links.next) {
          const nextUrl = new URL(lastPage._links.next, window.location.origin);
          return nextUrl.searchParams.get('cursor');
        }
        return undefined;
      },
      initialPageParam: null,
    });
  };

  const getPagesQuery = (spaceId: string) => {
    return useInfiniteQuery<PagesResponse, Error>({
      queryKey: ['confluencePages', spaceId],
      queryFn: async ({ pageParam = null }) => {
        const url = new URL(`${apiBaseUrl}/confluence/pages`);
        url.searchParams.append('spaceId', spaceId);
        url.searchParams.append('limit', '10');
        if (pageParam) {
          url.searchParams.append('cursor', pageParam as string);
        }

        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: getHeaders(),
        });

        if (!response.ok) {
          Sentry.captureException('Failed to fetch Confluence pages');
          throw new Error('Failed to fetch Confluence pages');
        }
        return response.json();
      },
      getNextPageParam: (lastPage) => {
        if (lastPage._links.next) {
          const nextUrl = new URL(lastPage._links.next, window.location.origin);
          return nextUrl.searchParams.get('cursor');
        }
        return undefined;
      },
      initialPageParam: null,
      enabled: !!spaceId,
    });
  };

  const exportToConfluenceMutation = useMutation<
    { message: string; status: string; url: string },
    Error,
    ExportToConfluenceParams
  >({
    mutationFn: async ({ documentId, spaceId, parentPageId, newPageTitle }) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append('documentId', documentId);
      urlencoded.append('spaceId', spaceId);
      urlencoded.append('parentPageId', parentPageId);
      if (newPageTitle) {
        urlencoded.append('newPageTitle', newPageTitle);
      }

      const response = await fetch(
        `${apiBaseUrl}/documentExport/exportConfluencePage`,
        {
          method: 'POST',
          headers: getHeaders(),
          body: urlencoded,
        }
      );

      if (!response.ok) {
        throw new Error('Failed to export to Confluence');
      }

      const data = await response.json();
      return data;
    },
    onSuccess: (data) => {
      toast({
        title: 'Export Successful',
        description: (
          <div>
            <p>Your document has been exported successfully.</p>
            <a
              href={data.url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline text-fluency-700"
            >
              Click here to open in Confluence
            </a>
          </div>
        ),
        duration: Infinity,
      });
    },
    onError: () => {
      Sentry.captureException('Failed to export to Confluence');
      toast({
        title: 'Failed to export to Confluence',
        variant: 'destructive',
      });
    },
  });

  const removeConfluenceMutation = useMutation<string, Error>({
    mutationFn: async () => {
      const response = await fetch(
        `${apiBaseUrl}/confluence/removeInstallation`,
        {
          method: 'DELETE',
          headers: getHeaders(),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to remove Confluence installation');
      }

      return response.text();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['confluenceConnectionStatus'],
      });
      toast({ title: 'Confluence installation removed successfully' });
    },
    onError: () => {
      Sentry.captureException('Failed to remove Confluence installation');
      toast({
        title: 'Failed to remove Confluence installation',
        variant: 'destructive',
      });
    },
  });

  return {
    installConfluence: installConfluenceMutation.mutate,
    isInstalling: installConfluenceMutation.isPending,
    installError: installConfluenceMutation.error,
    installProgress,

    connectionStatus: getConnectionStatusQuery.data,
    isLoadingConnectionStatus: getConnectionStatusQuery.isLoading,
    connectionStatusError: getConnectionStatusQuery.error,

    getSpacesQuery,
    getPagesQuery,

    exportToConfluence: exportToConfluenceMutation.mutate,
    isExporting: exportToConfluenceMutation.isPending,
    exportError: exportToConfluenceMutation.error,

    removeConfluence: removeConfluenceMutation.mutate,
    isRemoving: removeConfluenceMutation.isPending,
    removeError: removeConfluenceMutation.error,
  };
};
