import { useState, useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { ExtensionStatus } from '../types.js';

export const useExtensionStatus = () => {
  const [extensionStatus, setExtensionStatus] =
    useState<ExtensionStatus>('checking');
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { isAuthenticated, establishSecureChannel, secureChannel } = useAuth();

  const log = useCallback((message: string, ...args: any[]) => {
    if (
      import.meta.env.NODE_ENV === 'development' ||
      import.meta.env.NODE_ENV === 'staging'
    ) {
      console.log(`[ExtensionStatus] ${message}`, ...args);
    }
  }, []);

  const debouncedSetExtensionStatus = useMemo(
    () =>
      debounce((status: ExtensionStatus) => {
        setExtensionStatus(status);
        setIsRefreshing(false);
      }, 500),
    []
  );

  const checkExtensionInstalled = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      const timeout = setTimeout(() => {
        resolve(false);
      }, 1000);

      const listener = (event: MessageEvent) => {
        if (event.data.type === 'EXTENSION_INSTALLED') {
          clearTimeout(timeout);
          window.removeEventListener('message', listener);
          resolve(true);
        }
      };

      window.addEventListener('message', listener);
      window.postMessage({ type: 'CHECK_EXTENSION_INSTALLED' }, '*');
    });
  }, []);

  const checkExtensionStatus = useCallback(async (): Promise<void> => {
    log('Checking extension status');
    setIsRefreshing(true);

    let newStatus: ExtensionStatus;

    try {
      const isInstalled = await checkExtensionInstalled();
      if (isInstalled) {
        if (establishSecureChannel) {
          await establishSecureChannel();
          if (secureChannel) {
            newStatus = 'connected';
            log('Extension is installed and connected');
          } else {
            newStatus = 'installed_not_connected';
            log('Extension is installed but not connected');
          }
        } else {
          throw new Error('establishSecureChannel is not available');
        }
      } else {
        newStatus = 'not_installed';
        log('Extension is not installed');
      }
    } catch (error) {
      log('Error checking extension status:', error);
      newStatus = 'not_installed';
    }

    debouncedSetExtensionStatus(newStatus);
    setTimeout(() => setIsRefreshing(false), 500);
  }, [
    establishSecureChannel,
    secureChannel,
    log,
    debouncedSetExtensionStatus,
    checkExtensionInstalled,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      const initialCheck = debounce(() => {
        checkExtensionStatus();
      }, 500);
      initialCheck();
      return () => initialCheck.cancel();
    }
    return () => {};
  }, [isAuthenticated, checkExtensionStatus]);

  return { extensionStatus, isRefreshing, checkExtensionStatus };
};
