import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@fluency/ui/components/ui/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Button } from '@fluency/ui/components/ui/button';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Loader2, RefreshCw, Trash } from 'lucide-react';
import { useConfluenceManagement } from '@fluency/ui/hooks';
import ConfluenceIcon from './ConfluenceIcon.js';
const IntegrationsTable = () => {
  const {
    installConfluence,
    isInstalling,
    installError,
    connectionStatus,
    isLoadingConnectionStatus,
    connectionStatusError,
    removeConfluence,
    isRemoving,
    removeError,
  } = useConfluenceManagement();

  const handleInstall = () => {
    installConfluence();
  };

  const handleRemove = () => {
    removeConfluence();
  };

  const renderConnectionStatus = () => {
    if (isLoadingConnectionStatus) {
      return (
        <Badge variant="secondary">
          <RefreshCw className="w-4 mr-1 animate-spin" />
          Checking connection status
        </Badge>
      );
    }
    if (connectionStatusError) {
      return (
        <Badge variant="destructive">
          Error: {connectionStatusError.message}
        </Badge>
      );
    }
    if (connectionStatus && connectionStatus.status === 'connected') {
      return (
        <Badge className="bg-green-500 capitalize">
          {connectionStatus.status}
        </Badge>
      );
    }
    return null;
  };

  const isConnected = connectionStatus?.status === 'connected';

  const integrations = [
    {
      name: 'Confluence',
      icon: ConfluenceIcon,
      status: renderConnectionStatus(),
      action: isConnected ? (
        <Button onClick={handleRemove} disabled={isRemoving} variant="ghost">
          {isRemoving ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Removing...
            </>
          ) : (
            <>
              <Trash className="h-4 w-4" />
            </>
          )}
        </Button>
      ) : !isLoadingConnectionStatus ? (
        <Button onClick={handleInstall} disabled={isInstalling}>
          {isInstalling ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Opening Atlassian...
            </>
          ) : (
            'Install Confluence'
          )}
        </Button>
      ) : null,
      error: installError ? (
        <Badge variant="destructive">
          Installation error: {installError.message}
        </Badge>
      ) : removeError ? (
        <Badge variant="destructive">
          Removal error: {removeError.message}
        </Badge>
      ) : null,
    },
  ];

  return (
    <Card className="border-0">
      <CardHeader className="pt-0">
        <CardTitle>Integrations</CardTitle>
        <CardDescription>
          Add and manage integrations with third-party services.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableBody>
            {integrations.map((integration, index) => (
              <TableRow
                key={integration.name}
                className={`
                  ${index === 0 ? 'border-t' : ''} 
                  border-b
                `}
              >
                <TableCell className="py-4 border-t">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <integration.icon className="h-6 w-6 text-[#0c66e4]" />
                      <span className="font-medium">
                        {integration.name}
                      </span>{' '}
                      {integration.status}
                      {integration.error}
                    </div>
                    <div className="flex items-center gap-2"></div>
                  </div>
                </TableCell>
                <TableCell className="text-right py-4 border-t">
                  {integration.action}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isInstalling && (
          <p className="mt-2 text-sm text-muted-foreground w-full text-center">
            <RefreshCw className="w-4 mr-2 animate-spin inline" />
            Please complete the authorization in the new tab. You may need to
            allow pop-ups for this site.
          </p>
        )}
      </CardContent>
    </Card>
  );
};

export default IntegrationsTable;
