import { ReactNode } from 'react';
import Header from './Header.js';
import Sidebar from './Sidebar.js';

const DashboardShell = ({ children }: { children: ReactNode }) => (
  <div className="grid min-h-screen w-full grid-cols-[220px_1fr] bg-white">
    <Sidebar />
    <div className="flex flex-col">
      <Header />
      <div
        className="flex flex-1 flex-col overflow-hidden"
        style={{
          height: 'calc(100vh - 60px)',
          maxHeight: 'calc(100vh - 60px)',
          minHeight: 'calc(100vh - 60px)',
        }}
      >
        <div className="w-full h-full max-h-full min-h-full overflow-y-auto items-start text-sm font-normal text-slate-900">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default DashboardShell;
