import { toast } from '@fluency/ui/components/ui/use-toast';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useAuth } from '../providers/auth/AuthProvider';

export interface BillingDetailsData {
  email: string;
  name: string;
  phone?: string;
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  taxIdValue?: string;
  taxIdType: 'au_abn';
}

export const useBillingDetails = () => {
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const updateBillingDetails = async (data: BillingDetailsData) => {
    setIsLoading(true);
    const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

    try {
      const response = await fetch(`${apiBaseUrl}/billing`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to update billing details');
      }

      toast({
        title: 'Billing details updated successfully',
        description: 'Your billing information has been updated.',
      });

      return true;
    } catch (error) {
      Sentry.captureException('Failed to update billing details');
      toast({
        title: 'Error',
        description: 'Failed to update billing details. Please try again.',
        variant: 'destructive',
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { updateBillingDetails, isLoading };
};
