export const loginUtil = async (
  redirectImmediately: boolean = true
): Promise<string | void> => {
  try {
    let authUrl: string;
    const url = import.meta.env.VITE_SERVER_API_URL;
    let state: string;

    if (window.electron) {
      await window.electron.ipcRenderer.invoke('workos');
      state = 'electron';
    } else {
      const isLocalhost = window.location.hostname === 'localhost';
      state = isLocalhost ? 'web-local' : 'web';
    }

    const redirectUri = `${url}/auth/authenticateWithCode?state=${state}`;
    const response = await fetch(
      `${url}/auth/getAuthUrl?redirectUrl=${encodeURIComponent(redirectUri)}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    authUrl = data.authUrl;

    if (redirectImmediately) {
      if (window.electron) {
        window.open(authUrl, '_blank', 'noopener,noreferrer');
      } else {
        window.open(authUrl, '_self');
      }
    } else {
      return authUrl;
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};
