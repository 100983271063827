import { PauseIcon, PlayIcon } from '@heroicons/react/20/solid';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import { StopIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { toast } from '@fluency/ui/components/ui/use-toast';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { useNavigate } from '@tanstack/react-router';
import { useGetDocs } from '@fluency/ui/hooks';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';

interface ElectronAPI {
  ipcRenderer: {
    invoke(channel: string, ...args: unknown[]): Promise<unknown>;
    on(channel: string, callback: (...args: any[]) => void): void;
    removeListener(channel: string, callback: (...args: any[]) => void): void;
  };
  on(channel: string, callback: (...args: any[]) => void): void;
  removeListener(channel: string, callback: (...args: any[]) => void): void;
}

declare global {
  interface Window {
    electron?: ElectronAPI;
  }
}

export default function RecordButton() {
  const { user, accessToken, orgId } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [documentDescription, setDocumentDescription] = useState('');
  const navigate = useNavigate();
  const { refetch } = useGetDocs();

  const handleRecording = async () => {
    const name = documentName.trim() || 'Untitled Document';
    const description =
      documentDescription.trim() || 'Enter document description here...';

    if (isRecording) {
      setIsRecording(false);
      setIsLoading(true);
      if (window.electron && user) {
        try {
          navigate({
            to: '/documents',
            replace: true,
          });

          const result = (await window.electron.ipcRenderer.invoke(
            'run-steps',
            {
              processName: name,
              processDescription: description,
              token: accessToken,
              tenantId: orgId,
              userId: user.id,
            }
          )) as {
            success: boolean;
            docId?: string;
            error?: string;
          };

          if (result.success && result.docId) {
            refetch();
            navigate({
              to: '/document/$id',
              replace: true,
              params: { id: result.docId },
            });
          } else {
            toast({
              variant: 'destructive',
              title: 'Error processing recording',
              description: result.error || 'Please try again.',
            });
            console.error('Error in run-steps:', result.error);
          }
        } catch (error) {
          Sentry.captureException(error);
          console.error('Error invoking run-steps:', error);
        } finally {
          setIsLoading(false); // Set loading to false when the process is complete
        }
      }
    } else {
      if (window.electron) {
        try {
          await window.electron.ipcRenderer.invoke('start-record');
          setIsRecording(true);
        } catch (error) {
          toast({
            variant: 'destructive',
            title: 'Error starting recording',
            description: 'Please try again.',
          });
          console.error('Error starting recording:', error);
        }
      }
    }
  };

  const [isPaused, setIsPaused] = useState(false);
  const handlePauseResume = () => {
    if (window.electron) {
      if (isPaused) {
        window.electron.ipcRenderer.invoke('resume-record');
      } else {
        window.electron.ipcRenderer.invoke('pause-record');
      }
      setIsPaused(!isPaused);
    }
  };

  const FormSchema = z.object({
    name: z
      .string()
      .min(3, { message: 'Process name must be at least 3 characters.' })
      .max(60, { message: 'Process name must not exceed 60 characters.' }),
    description: z
      .string()
      .min(3, { message: 'Process description must be at least 3 characters.' })
      .max(240, {
        message: 'Process description must not exceed 240 characters.',
      }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    setDocumentName(data.name);
    setDocumentDescription(data.description);
    handleRecording();
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          {!isRecording ? (
            isLoading ? (
              <Button size="sm" disabled variant="secondary">
                Processing Recording...
              </Button>
            ) : (
              <Button size="sm">
                <VideoCameraIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Record New Process
              </Button>
            )
          ) : (
            <Button
              size="sm"
              variant="destructive"
              className="bg-red-600 text-white"
            >
              <StopIcon className="h-5 mr-2" />
              Recording
            </Button>
          )}
        </DialogTrigger>
        {!isRecording ? (
          <DialogContent
            onInteractOutside={(e) => isLoading && e.preventDefault()}
          >
            <>
              <DialogHeader>
                <DialogTitle>
                  Please provide context for the process you wish to record
                </DialogTitle>
              </DialogHeader>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-6"
                >
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Process name</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter a clear, descriptive title..."
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Process description</FormLabel>
                        <FormControl>
                          <Textarea
                            {...field}
                            className="h-32 max-h-64"
                            placeholder="Describe the process in detail for the best results..."
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="w-full flex justify-end">
                    <Button type="submit">
                      <VideoCameraIcon className="h-5 mr-2" />
                      Start Recording
                    </Button>
                  </div>
                </form>
              </Form>
            </>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Recording!</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              You are currently recording a process. Click the button below to
              stop recording.
            </DialogDescription>
            <div className="flex justify-between">
              <DialogClose>
                <Button
                  onClick={handleRecording}
                  variant="destructive"
                  className="bg-red-600 text-white"
                >
                  <StopIcon className="h-5 mr-2" /> <span>Stop Recording</span>
                </Button>
              </DialogClose>
              <Button onClick={handlePauseResume} variant="secondary">
                {isPaused ? (
                  <>
                    <PlayIcon className="h-5 mr-2" />
                    <span>Resume Process</span>
                  </>
                ) : (
                  <>
                    <PauseIcon className="h-5 mr-2" />
                    <span>Pause Process</span>
                  </>
                )}
              </Button>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
