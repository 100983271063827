import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import type {
  Team,
  TeamsApiResponse,
} from '@fluency/ui/features/ManageTeam/types';

const useGetTeams = (): UseQueryResult<Team[], Error> => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const { orgId, accessToken } = useAuth();

  return useQuery<Team[], Error>({
    queryKey: ['teams', orgId],
    queryFn: async (): Promise<Team[]> => {
      const response = await fetch(`${apiBaseUrl}/teams/list`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: TeamsApiResponse = await response.json();
      return data.teams;
    },
    enabled: !!orgId,
  });
};

export default useGetTeams;
