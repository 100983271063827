import { Badge } from '@fluency/ui/components/ui/badge';
import { Link } from '@tanstack/react-router';

interface NavLinkProps {
  href: string;
  icon: React.ReactNode;
  label: string;
  notificationCount?: number;
  isActive?: boolean;
  isMobile?: boolean;
}

const NavLink = ({ href, icon, label, notificationCount }: NavLinkProps) => (
  <Link
    to={href}
    className="flex items-center gap-3 rounded-md px-3 py-2 transition-all text-muted-foreground hover:text-primary whitespace-nowrap overflow-hidden overflow-ellipsis"
    activeProps={{ className: 'bg-muted px-3 py-2 text-primary' }}
  >
    {icon}
    <span className="flex-1 overflow-hidden overflow-ellipsis">{label}</span>
    {notificationCount && (
      <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
        {notificationCount}
      </Badge>
    )}
  </Link>
);

export default NavLink;
