import { useState } from 'react';
import { Card, CardContent } from '@fluency/ui/components/ui/card';
import { Button } from '@fluency/ui/components/ui/button';
import { CreditCard, CreditCardIcon } from 'lucide-react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@fluency/ui/components/ui/alert';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useSubscription } from '@fluency/ui/hooks';
import { format } from 'date-fns';
import { RemoveCard } from './RemoveCard.js';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';

interface StripeSession {
  sessionId: string;
  sessionUrl: string;
}

export default function PaymentMethodCard() {
  const { subscriptionStatus } = useSubscription();
  const { subscription, paymentMethod } = subscriptionStatus || {};
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { isElectron } = useElectron();

  const fetchStripeSession = async () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    const urlencoded = new URLSearchParams();
    urlencoded.append('action', 'updatePaymentMethod');
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow' as RequestRedirect,
    };
    const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
    const successUrl = isElectron ? 'fluency://' : window.location.origin;
    const cancelUrl = isElectron ? 'fluency://' : window.location.origin;

    try {
      const response = await fetch(
        `${apiBaseUrl}/billing/updatePaymentMethod?successUrl=${successUrl}&cancelUrl=${cancelUrl}`,
        requestOptions
      );
      const result: StripeSession = await response.json();
      if (window.electron) {
        window.electron.ipcRenderer.invoke('open-external', result.sessionUrl);
      } else {
        window.open(result.sessionUrl, '_blank', 'noopener,noreferrer');
      }
    } catch (error) {
      console.error('Error fetching Stripe session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPaymentMethod = () => {
    fetchStripeSession();
  };

  return (
    <Card className="bg-background mb-4">
      {subscription?.status === 'trialing' && !paymentMethod ? (
        <Alert variant="destructive">
          <AlertTitle>No payment method set.</AlertTitle>
          <AlertDescription>
            Add a payment method before{' '}
            {subscription.trialEnd &&
              format(new Date(subscription.trialEnd), 'dd MMMM yyyy')}{' '}
            to continue using Fluency after your trial.
          </AlertDescription>
          <Button
            onClick={handleEditPaymentMethod}
            disabled={isLoading}
            variant="secondary"
            size="sm"
            className="mt-2 gap-2"
          >
            <CreditCardIcon />
            {isLoading ? 'Loading...' : 'Add payment method'}
          </Button>
        </Alert>
      ) : paymentMethod ? (
        <CardContent className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <CreditCard className="h-6 w-6 text-gray-400" />
              <div>
                <p className="font-medium">
                  <span className="capitalize">
                    {paymentMethod.card.brand}{' '}
                  </span>
                  ending in {paymentMethod.card.last4}
                </p>
                <p className="text-sm text-gray-500">
                  Expiry{' '}
                  {paymentMethod.card.expMonth.toString().padStart(2, '0')}/
                  {paymentMethod.card.expYear}
                </p>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <Button
                variant="secondary"
                className="h-8"
                onClick={handleEditPaymentMethod}
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Edit'}
              </Button>
              <RemoveCard paymentMethodId={paymentMethod.id} />
            </div>
          </div>
        </CardContent>
      ) : (
        <CardContent className="p-4">
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-500">No payment method set</p>
            <Button
              variant="secondary"
              className="h-8"
              onClick={handleEditPaymentMethod}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Add payment method'}
            </Button>
          </div>
        </CardContent>
      )}
    </Card>
  );
}
