import { Badge } from '@fluency/ui/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import { useSubscription } from '@fluency/ui/hooks';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';
import { Link } from '@tanstack/react-router';
import { ChevronDown, Clock, Cog, HelpCircle, RefreshCw } from 'lucide-react';
import { useEffect, useState } from 'react';
import Logout from './Logout.js';
import RecordButton from './RecordButton.js';
import Welcome from './Welcome.js';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider.js';

const getDaysLeft = (trialEnd: string | Date): number => {
  const now = new Date();
  const end = new Date(trialEnd);
  const diffTime = end.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const Header = () => {
  const { user, organisationName, accessToken } = useAuth();
  const { subscriptionStatus } = useSubscription();
  const { isElectron } = useElectron();
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false);

  const isTrialing = subscriptionStatus?.status === 'trialing';
  const hasPaymentMethod = !!subscriptionStatus?.paymentMethod;
  const daysLeft = isTrialing
    ? subscriptionStatus?.subscription?.trialEnd
      ? getDaysLeft(subscriptionStatus.subscription.trialEnd)
      : 0
    : 0;

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      try {
        const apiUrl = import.meta.env.VITE_SERVER_API_URL;
        const response = await fetch(
          `${apiUrl}/user/checkAndUpdateOnboardingStatus`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const result: { onboardingComplete: boolean } = await response.json();
        console.log('Onboarding status:', result.onboardingComplete);
        if (result.onboardingComplete === false) {
          setIsWelcomeOpen(true);
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
      }
    };

    checkOnboardingStatus();
  }, [accessToken]);

  return (
    <>
      <header className="flex items-center gap-4 border-b bg-muted/40 px-4 h-[60px] min-h-[60px] ">
        <div className="w-full flex gap-2 ml-2">
          {isElectron && <RecordButton />}
          {isTrialing && !hasPaymentMethod && (
            <Badge
              variant="outline"
              className="p-0 border-0 rounded-xl items-center gap-2 flex
              text-gray-500"
            >
              <Clock className="w-5" />
              <div className="flex flex-col">
                <div>{`${daysLeft} ${
                  daysLeft === 1 ? 'day' : 'days'
                } left in your trial.`}</div>
                <div className="font-normal">
                  Upgrade now to ensure uninterrupted access to Fluency.
                </div>
              </div>
            </Badge>
          )}
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex cursor-pointer">
              <div className="flex flex-col items-end justify-center text-sm w-fit">
                {user && organisationName ? (
                  <>
                    <span className="font-medium w-fit max-w-[200px] truncate">
                      {user.firstName && user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : user.email}
                    </span>
                    <span className="text-xs text-gray-600 font-normal max-w-[200px] truncate">
                      {organisationName}
                    </span>
                  </>
                ) : (
                  <div className="flex items-center gap-2 text-gray-600">
                    <RefreshCw className="w-4 h-4 animate-spin text-gray-600" />
                    <span className="font-medium w-fit max-w-[200px] truncate">
                      Loading...
                    </span>
                  </div>
                )}
              </div>
              <ChevronDown className="text-gray-700 w-4 ml-2" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="">
            <DropdownMenuItem>
              <Link
                to="/profile/$setting"
                params={{
                  setting: 'general',
                }}
                className="font-medium w-full"
              >
                <div className="w-full flex justify-between items-center">
                  <span>Settings</span>
                  <Cog className="text-gray-400 h-5" />
                </div>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="font-medium cursor-pointer flex w-full justify-between items-center"
              onClick={() => setIsWelcomeOpen(true)}
            >
              How-to <HelpCircle className="text-gray-400 h-5" />
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="font-medium cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              <Logout />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </header>
      <Welcome isOpen={isWelcomeOpen} onOpenChange={setIsWelcomeOpen} />
    </>
  );
};

export default Header;
