import React, { useState } from 'react';
import { Label } from '@fluency/ui/components/ui/label';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { useUpdateUserProfile } from '@fluency/ui/features/UserProfile/hooks';
import { PenSquareIcon } from 'lucide-react';
import { WorkOSUser } from '@fluency/ui/providers/auth/types/WorkOSUser';

interface DisplayNameProps {
  user: WorkOSUser | null;
}

export function DisplayName({ user }: DisplayNameProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');

  const { mutate: updateProfile, isPending } = useUpdateUserProfile();

  const displayName =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.email;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateProfile(
      { firstName, lastName },
      {
        onSuccess: () => setIsEditing(false),
      }
    );
  };

  if (isEditing) {
    return (
      <form onSubmit={handleSubmit}>
        <Label className="block mb-2">Display Name</Label>
        <div className="flex gap-4 mb-2">
          <div className="flex-1">
            <Label
              htmlFor="firstName"
              className="text-xs text-gray-500 mb-1 block"
            >
              First Name
            </Label>
            <Input
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
          </div>
          <div className="flex-1">
            <Label
              htmlFor="lastName"
              className="text-xs text-gray-500 mb-1 block"
            >
              Last Name
            </Label>
            <Input
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <Button type="submit" disabled={isPending} size="sm">
            {isPending ? 'Saving...' : 'Save'}
          </Button>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div>
      <Label className="block mb-2">Display Name</Label>
      <div className="flex w-fit gap-2 justify-between items-center">
        <p className="text-sm text-gray-700">{displayName}</p>
        <div onClick={() => setIsEditing(true)}>
          <PenSquareIcon className="w-4 mt-0.5 cursor-pointer" />
        </div>
      </div>
    </div>
  );
}
