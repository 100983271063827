import { DashboardShell } from '@fluency/ui/components';
import { Separator } from '@fluency/ui/components/ui/separator';
import { PermissionGateProvider } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { Label } from '@radix-ui/react-dropdown-menu';
import { Link } from '@tanstack/react-router';
import { Building2, User2 } from 'lucide-react';
import AdvancedSettings from './components/Advanced/AdvancedSettings.js';
import Billing from './components/Billing/Billing.js';
import Branding from './components/Branding/index.js';
import GeneralCard from './components/GeneralCard/GeneralCard.js';
import IntegrationsCard from './components/Integrations/IntegrationsCard.js';
import OrganisationCard from './components/Organisation/Organisation.js';
import PIICard from './components/PII/PII.js';

interface UserProfileProps {
  setting: string;
}

interface NavItem {
  to: string;
  setting: string;
  label: string;
  permissions: string[];
  type: 'personal' | 'organisation';
}

const navItems: NavItem[] = [
  {
    to: '/profile/$setting',
    setting: 'general',
    label: 'Profile',
    permissions: ['user:read'],
    type: 'personal',
  },
  {
    to: '/profile/$setting',
    setting: 'organisation',
    label: 'Users',
    permissions: ['org:read'],
    type: 'organisation',
  },
  {
    to: '/profile/$setting',
    setting: 'billing',
    label: 'Billing',
    permissions: ['subscription:read'],
    type: 'organisation',
  },
  {
    to: '/profile/$setting',
    setting: 'settings',
    label: 'Settings',
    permissions: ['org:update'],
    type: 'organisation',
  },
  {
    to: '/profile/$setting',
    setting: 'integrations',
    label: 'Integrations',
    permissions: ['user:update'],
    type: 'personal',
  },
  {
    to: '/profile/$setting',
    setting: 'pii',
    label: 'Auto-Redact PII',
    permissions: ['org:update'],
    type: 'organisation',
  },
  {
    to: '/profile/$setting',
    setting: 'branding',
    label: 'Branding',
    permissions: ['org:update'],
    type: 'organisation',
  },
];

const NavLink = ({
  item,
  currentSetting,
}: {
  item: NavItem;
  currentSetting: string;
}) => (
  <PermissionGateProvider permissions={item.permissions}>
    <Link
      to={item.to}
      params={{ setting: item.setting }}
      className={`font-semibold flex items-center ${
        currentSetting === item.setting ? 'text-primary' : ''
      }`}
    >
      {item.label}
    </Link>
  </PermissionGateProvider>
);

const UserProfile = ({ setting }: UserProfileProps) => {
  const personalItems = navItems.filter((item) => item.type === 'personal');
  const orgItems = navItems.filter((item) => item.type === 'organisation');
  return (
    <DashboardShell>
      <main className="flex flex-1 flex-col gap-4 md:gap-8 p-10">
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 grid-cols-[140px_1fr]">
          <nav className="grid gap-4 text-sm text-muted-foreground">
            <Label className="font-semibold text-gray-400 cursor-default flex items-center gap-2">
              <User2 />
              Personal
            </Label>
            {personalItems.map((item) => (
              <NavLink
                key={item.setting}
                item={item}
                currentSetting={setting}
              />
            ))}
            <PermissionGateProvider permissions={['org:read']}>
              <Separator />
              <Label className="font-semibold text-gray-400 cursor-default flex items-center gap-2">
                <Building2 />
                Organisation
              </Label>
              {orgItems.map((item) => (
                <NavLink
                  key={item.setting}
                  item={item}
                  currentSetting={setting}
                />
              ))}
            </PermissionGateProvider>
          </nav>

          <div className="grid gap-6">
            {setting === 'general' && <GeneralCard />}
            <PermissionGateProvider permissions={['subscription:read']}>
              {setting === 'billing' && <Billing />}
            </PermissionGateProvider>
            {setting === 'integrations' && <IntegrationsCard />}
            <PermissionGateProvider permissions={['org:read']}>
              {setting === 'organisation' && <OrganisationCard />}
            </PermissionGateProvider>
            <PermissionGateProvider permissions={['org:update']}>
              {setting === 'settings' && <AdvancedSettings />}
            </PermissionGateProvider>
            <PermissionGateProvider permissions={['org:update']}>
              {setting === 'branding' && <Branding />}
            </PermissionGateProvider>
            <PermissionGateProvider permissions={['pii:update']}>
              {setting === 'pii' && <PIICard />}
            </PermissionGateProvider>
          </div>
        </div>
      </main>
    </DashboardShell>
  );
};

export default UserProfile;
