import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Label } from '@fluency/ui/components/ui/label';
import { useSubscription } from '@fluency/ui/hooks';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Check, RefreshCw } from 'lucide-react';
import { DisplayName } from './DisplayName';

export default function GeneralCard() {
  const { user, orgId, isLoading, organisationName, role } = useAuth();
  const { subscriptionStatus } = useSubscription();

  const formatSubscriptionStatus = (status: string): string => {
    if (status === 'trialing') {
      return 'Pro (free trial)';
    }
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const displayStatus = subscriptionStatus
    ? formatSubscriptionStatus(subscriptionStatus.status)
    : 'Loading...';

  if (isLoading) {
    return (
      <div className="text-center flex justify-center items-center">
        <RefreshCw className="w-6 h-6 animate-spin text-gray-600" />
      </div>
    );
  }

  return (
    <Card className="border-0">
      <CardHeader className="pt-0 m-0">
        <CardTitle>Profile</CardTitle>
        <CardDescription>View details related to your account.</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-6">
          <DisplayName user={user} />
          <div>
            <Label className="mb-2 flex items-center">
              Email address
              {user?.emailVerified ? (
                <span className="ml-2 flex items-center text-xs bg-green-200 py-0.5 pr-3 rounded-full">
                  <Check className="h-3.5 m-0 p-0" />
                  Verified
                </span>
              ) : (
                <span className="ml-2 flex items-center text-xs bg-yellow-200 py-0.5 px-3 rounded-full">
                  Not verified
                </span>
              )}
            </Label>
            <p className="text-sm text-gray-700">{user?.email}</p>
          </div>
          {orgId && (
            <div>
              <Label className="block mb-2">Organisation</Label>
              <p className="text-sm text-gray-700">{organisationName}</p>
            </div>
          )}
          {subscriptionStatus?.status && (
            <div>
              <Label className="block mb-2">
                License Status
                <span className="ml-2 text-gray-400 font-normal text-xs">
                  This is managed by the {organisationName} admin/s.
                </span>
              </Label>
              <p className="text-sm text-gray-700">{displayStatus}</p>
            </div>
          )}
          {role && (
            <div>
              <Label className="block mb-2">
                Role
                <span className="ml-2 text-gray-400 font-normal text-xs">
                  This is managed by the {organisationName} admin/s.
                </span>
              </Label>
              <p className="text-sm text-gray-700 capitalize">{role}</p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
