import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Team } from '../types';
import { useNavigate } from '@tanstack/react-router';

interface CreateTeamResponse {
  message: string;
  createdTeam: {
    teamId: string;
    teamName: string;
    teamDescription: string;
    tenantId: string;
  };
}

async function createTeam(
  teamName: string,
  description: string,
  accessToken: string
): Promise<CreateTeamResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const endpoint = `${apiBaseUrl}/teams/create`;

  const data = JSON.stringify({
    teamName: teamName,
    teamDescription: description,
  });

  const config = {
    method: 'post',
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  };

  return axios
    .request<CreateTeamResponse>(config)
    .then((response) => response.data);
}

export function useCreateTeam() {
  const { accessToken, orgId } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation<
    CreateTeamResponse,
    Error,
    { teamName: string; description: string }
  >({
    mutationKey: ['createTeam'],
    mutationFn: async ({ teamName, description }) => {
      return createTeam(teamName, description, accessToken ?? '');
    },
    onMutate: async ({ teamName, description }) => {
      await queryClient.cancelQueries({ queryKey: ['teams', orgId] });

      const previousTeams = queryClient.getQueryData<Team[]>(['teams', orgId]);

      queryClient.setQueryData<Team[]>(['teams', orgId], (old) => [
        ...(old || []),
        {
          teamId: 'temp-id',
          teamName: teamName,
          teamDescription: description,
          orgId: orgId,
        },
      ]);

      return { previousTeams };
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'There was a problem with your request.',
      });
    },
    onSuccess: (data) => {
      const teamId = data.createdTeam.teamId;
      toast({
        variant: 'default',
        title: 'Success',
        description: 'Team created successfully.',
      });
      navigate({
        to: '/teams/$id',
        replace: true,
        params: { id: teamId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['teams', orgId] });
    },
  });
}
