import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@fluency/ui/components/ui/accordion';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Download, Folder, Settings, Users2 } from 'lucide-react';
import logo from '../../assets/icon-blue.svg';
import SubmitFeedback from './SubmitFeedback.js';
import NavLink from './NavLink.js';
import { useGetTeams } from '@fluency/ui/hooks';
import ExtensionStatusButton from '../ExtensionStatusButton';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';

const Sidebar = () => {
  const { data: teams } = useGetTeams();
  const { isAuthenticated } = useAuth();
  const { isElectron } = useElectron();

  return (
    <div className="border-r bg-muted/40 block w-[220px] overflow-x-hidden">
      <div className="flex h-full max-h-screen flex-col gap-4">
        <div className="flex items-center border-b px-4 h-[60px]">
          <div className="flex items-center gap-2 font-semibold cursor-default">
            <img src={logo} className="h-6 w-6 " />
            <span>Fluency</span>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <nav className="grid items-start px-2 text-sm font-medium gap-2">
            <NavLink
              href="/documents"
              label="Process Vault"
              icon={<Folder className="h-4 w-4" />}
            />
            <Accordion type="single" collapsible className="w-full -mb-1">
              <AccordionItem value="item-1" className="w-full">
                <AccordionTrigger className="flex items-center gap-3 rounded-md px-3 -my-2 transition-all text-muted-foreground hover:text-primary">
                  <div className="flex items-center gap-3">
                    <Users2 className="h-4 w-4" />
                    <span className="truncate">Teams</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="ml-7 -mb-4">
                  {teams?.map((team) => (
                    <div key={team.teamId} className="w-44">
                      <NavLink
                        href={`/teams/${team.teamId}`}
                        label={team.teamName}
                        icon={<Folder className="h-4 w-4" />}
                      />
                    </div>
                  ))}
                  <NavLink
                    href="/teams/manage"
                    label="Manage Teams"
                    icon={<Settings className="h-4 w-4" />}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <SubmitFeedback />
          </nav>
        </div>
        <div className="mt-auto p-4">
          {isAuthenticated && !isElectron && <ExtensionStatusButton />}
          {!isElectron && (
            <a
              href="https://getfluency.com.au/Download-Windows"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Badge
                className="w-full mx-0 flex items-center hover:underline pl-4 mb-2 py-1 rounded-md cursor-pointer h-10"
                variant="outline"
              >
                <Download className="mr-2 w-4" /> Download for Windows
              </Badge>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
