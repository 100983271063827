import { Card, CardContent } from '@fluency/ui/components/ui/card';
import { FileText, AlertCircle, RefreshCw } from 'lucide-react';
import { useSubscription } from '@fluency/ui/hooks';
import UpdateBilling from './UpdateBilling.js';

export default function BillingInformationCard() {
  const { billingDetails, isLoadingBilling, billingError } = useSubscription();

  const renderAddressField = (field: string | undefined) =>
    field && (
      <>
        {field}
        <br />
      </>
    );

  const renderContent = () => {
    if (isLoadingBilling) {
      return (
        <div className="flex items-center justify-center h-32">
          <RefreshCw className="h-6 w-6 animate-spin text-gray-400" />
          <span className="ml-2">Loading billing information...</span>
        </div>
      );
    }

    if (billingError) {
      return (
        <div className="flex items-center justify-center h-32 text-red-500">
          <AlertCircle className="h-6 w-6 mr-2" />
          <span>Error loading billing information</span>
        </div>
      );
    }

    if (!billingDetails) {
      return (
        <div className="flex items-center justify-center h-32 text-gray-500">
          No billing information available
        </div>
      );
    }

    return (
      <div className="space-y-2">
        <div className="flex w-full justify-between">
          <span className="text-gray-500">Customer</span>
          <span>{billingDetails.name}</span>
        </div>
        <div className="flex w-full justify-between">
          <span className="text-gray-500">Email</span>
          <span>{billingDetails.email}</span>
        </div>
        <div className="flex w-full justify-between">
          <span className="text-gray-500">Address</span>
          <span className="text-right">
            {renderAddressField(billingDetails.address.line1)}
            {renderAddressField(billingDetails.address.line2)}
            {renderAddressField(billingDetails.address.city)}
            {renderAddressField(
              `${billingDetails.address.state}, ${billingDetails.address.postalCode}`
            )}
            {billingDetails.address.country && billingDetails.address.country}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Card className="bg-background w-full">
      <CardContent className="p-4">
        <div className="flex justify-between mb-4 items-center">
          <div className="flex items-center space-x-4">
            <FileText className="h-6 w-6 text-gray-400" />
            <p className="font-medium">Billing Information</p>
          </div>
          {!isLoadingBilling && !billingError && billingDetails && (
            <UpdateBilling />
          )}
        </div>
        {renderContent()}
      </CardContent>
    </Card>
  );
}
