import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { useUpdateTeam } from '../../hooks/useUpdateTeam.js';
import type { Team } from '../../types';
import { SquarePen } from 'lucide-react';
import { Separator } from '@fluency/ui/components/ui/separator';
import { AlertDialogTeamDelete } from './AlertDialogTeamDelete.js';

const teamSchema = z.object({
  teamName: z
    .string()
    .min(1, 'Team name is required')
    .max(30, 'Team name must be 30 characters or less'),
  teamDescription: z
    .string()
    .max(240, 'Description must be 240 characters or less')
    .optional(),
});

type TeamFormData = z.infer<typeof teamSchema>;

interface TeamProps {
  team: Team;
}

const EditTeamModal: React.FC<TeamProps> = ({ team }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, isPending, isSuccess } = useUpdateTeam();

  const form = useForm<TeamFormData>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamName: team.teamName,
      teamDescription: team.teamDescription ?? '',
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
    }
  }, [isSuccess]);

  const onSubmit = (data: TeamFormData) => {
    mutate({
      teamId: team.teamId,
      teamName: data.teamName,
      teamDescription: data.teamDescription,
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    form.reset();
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      handleCloseModal();
    } else {
      setIsOpen(open);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button size="sm" variant="secondary">
          <SquarePen className="w-5 mr-2" />
          Edit team
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit team</DialogTitle>
          <DialogDescription>
            Update the form below to edit your team.
          </DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="teamName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="teamDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} maxLength={240} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="w-full flex justify-end">
                <Button type="submit" disabled={isPending} size="sm">
                  Update team
                </Button>
              </div>
            </form>
          </Form>
        </FormProvider>
        <Separator className="my-3" />

        <div className="flex justify-between items-center bg-red-50 py-2 px-4 rounded-md">
          <div className="flex flex-col">
            <span className="text-sm font-semibold text-red-700">
              Delete team
            </span>
            <span className="text-sm text-red-700">
              Warning! This cannot be undone
            </span>
          </div>
          <AlertDialogTeamDelete teamId={team.teamId} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditTeamModal;
