import { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { ChevronLeft, ChevronRight, Download } from 'lucide-react';
import { useInvoices } from '@fluency/ui/hooks';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Button } from '@fluency/ui/components/ui/button';

export function Invoices() {
  const workOSContext = useAuth();
  const [startingAfter, setStartingAfter] = useState<string | undefined>(
    undefined
  );
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const { invoices, isLoading, error, hasMore } = useInvoices({
    limit: pageSize,
    startingAfter,
  });

  if (!workOSContext) {
    return (
      <div>WorkOS context not available. Please ensure you're logged in.</div>
    );
  }

  if (isLoading) return <div>Loading invoices...</div>;
  if (error) return <div>Error loading invoices: {error.message}</div>;

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    setStartingAfter(undefined); // Reset to fetch from the beginning
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    if (invoices && invoices.length > 0) {
      const lastInvoice = invoices[invoices.length - 1];
      if (lastInvoice) {
        setStartingAfter(lastInvoice.id);
      }
    }
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-semibold">History</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-muted-foreground mb-6">
          Download your previous invoices.
        </p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Invoice</TableHead>

              <TableHead>Due</TableHead>
              <TableHead>Paid</TableHead>
              <TableHead className="text-right"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices && invoices.length > 0 ? (
              invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell>
                    {new Date(invoice.created).toLocaleDateString()}
                  </TableCell>
                  <TableCell className="font-medium">
                    <span>Invoice #{invoice.id.slice(-8)}</span>
                  </TableCell>

                  <TableCell>{`$${(invoice.amountDue / 100).toFixed(
                    2
                  )}`}</TableCell>
                  <TableCell>{`$${(invoice.amountPaid / 100).toFixed(
                    2
                  )}`}</TableCell>
                  <TableCell className="text-right">
                    <a
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-secondary/80 h-9 px-3"
                      href={invoice.pdfLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Download className="h-4 w-4 mr-2" />
                      Download
                    </a>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="text-center">
                  No invoices found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="mt-4 flex justify-center items-center">
          <Button
            onClick={handlePrevPage}
            disabled={page === 1}
            size="icon"
            variant="ghost"
          >
            <ChevronLeft />
          </Button>
          <span className="font-semibold px-4">{page}</span>
          <Button
            onClick={handleNextPage}
            disabled={!hasMore}
            size="icon"
            variant="ghost"
          >
            <ChevronRight />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
