const url = import.meta.env.VITE_SERVER_API_URL;

interface WorkosOrganization {
  object: string;
  id: string;
  name: string;
  allowProfilesOutsideOrganization: boolean;
  domains: string[];
  createdAt: string;
  updatedAt: string;
}

export interface GetOrganisationResponse {
  workos: WorkosOrganization;
  tenantId: string;
  stripeCustomerId: string;
  companyLogoKey: string | null;
  createdBy: string;
  owner: string;
}

interface ApiResponse {
  data: GetOrganisationResponse;
}

export const getOrganisation = async (
  token: string
): Promise<GetOrganisationResponse> => {
  try {
    const response = await fetch(`${url}/organisation`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: ApiResponse = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching organization data:', error);
    throw error;
  }
};
