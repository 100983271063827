import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { logout as logoutUtil, decodeJWT, loginUtil } from './utils';
import {
  setStoredRefreshToken,
  getStoredRefreshToken,
  setStoredAccessToken,
  getStoredAccessToken,
  clearStoredTokens,
  setStoredOrganisationId,
} from './utils/localStorageUtils';
import {
  getOrganisation,
  getSubscriptionStatus,
  getUserProfile,
  refreshTokens,
} from './requests';
import { AuthContextType } from './types/AuthContextType';
import { getChallenge } from './requests/getChallenge';
import { verifyChallenge } from './requests/verifyChallenge';
import { DecodedToken } from './types/DecodedToken';
import { WorkOSUser } from './types/WorkOSUser';
import { WorkOSResponse } from './types/WorkOSResponse';
import { useElectron } from '../electron/ElectronContext';
import { OrgFormSubmittedData } from '@fluency/ui/features/OrgForm';
import { GetOrganisationResponse } from './requests/getOrganisation';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Auth Provider for WEB
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isElectron } = useElectron();
  const [authState, setAuthState] = useState({
    isLoading: false,
    isAuthenticated: false,
    user: null as WorkOSUser | null,
    accessToken: null as string | null,
    refreshToken: null as string | null,
    decodedToken: null as DecodedToken | null,
    orgId: null as string | null,
    organisationName: '',
    organisationResponse: null as GetOrganisationResponse | null,
    workOSResponse: null as WorkOSResponse | null,
    justLoggedOut: false,
    authError: null as string | null,
    subscription: null as string | null,
    hasCompletedOrgForm: false,
  });

  const broadcastLogout = useCallback(() => {
    localStorage.setItem(
      'auth_broadcast',
      JSON.stringify({ type: 'LOGOUT', timestamp: Date.now() })
    );
  }, []);

  const broadcastLogin = useCallback((user: WorkOSUser) => {
    localStorage.setItem(
      'auth_broadcast',
      JSON.stringify({ type: 'LOGIN', user, timestamp: Date.now() })
    );
  }, []);

  const [orgFormData, setOrgFormData] = useState<OrgFormSubmittedData | null>(
    null
  );
  const [secureChannel, setSecureChannel] = useState<string | null>(null);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [temporaryPermissions, setTemporaryPermissions] = useState<string[]>(
    []
  );

  const initialisationInProgress = useRef(false);
  const initialisationTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isInitialised, setisInitialised] = useState(false);

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // Add this new function
  const setTemporaryPermission = useCallback((permission: string) => {
    setTemporaryPermissions((prev) => [...prev, permission]);
  }, []);

  // Add this new function
  const clearTemporaryPermissions = useCallback(() => {
    setTemporaryPermissions([]);
  }, []);

  const logger = useCallback((message: string, data?: any) => {
    if (
      import.meta.env.NODE_ENV === 'development' ||
      import.meta.env.NODE_ENV === 'staging'
    ) {
      console.log(`[AuthProvider] ${message}`, data ? data : '');
    }
  }, []);

  const updateAuthState = useCallback((newState: Partial<typeof authState>) => {
    logger('Updating auth state to...', newState);
    setAuthState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const notifyExtension = useCallback(
    (authData: {
      token: string | null | undefined;
      user: WorkOSUser | null | undefined;
      role: string | null;
      permissions: string[];
      organisation: {
        id: string | null;
        name: string | null | undefined;
      };
      subscription: string | null | undefined;
    }) => {
      if (isElectron) {
        logger('Skipping extension notification in Electron environment');
        return;
      }

      if (secureChannel) {
        window.postMessage(
          {
            type: 'AUTH_STATE_UPDATED',
            extensionId: secureChannel,
            authState: authData,
          },
          '*'
        );
      } else {
        logger(
          'No secure channel established. Unable to send auth data to extension.'
        );
      }
    },
    [secureChannel, logger]
  );

  const sendAuthDataToExtension = useCallback(() => {
    if (isElectron) {
      logger('Skipping sending auth data to extension in Electron environment');
      return;
    }

    if (
      secureChannel &&
      authState.isAuthenticated &&
      authState.accessToken &&
      authState.user &&
      authState.decodedToken
    ) {
      logger('Sending auth data to extension');
      notifyExtension({
        token: authState.accessToken,
        user: authState.user,
        role: authState.decodedToken.role,
        permissions: authState.decodedToken.permissions || [],
        organisation: {
          id: authState.orgId,
          name: authState.organisationName,
        },
        subscription: authState.subscription,
      });
    } else {
      logger(
        'Unable to send auth data to extension. Secure channel or auth data not available.'
      );
    }
  }, [secureChannel, authState, notifyExtension, logger]);

  useEffect(() => {
    if (!isElectron && authState.isAuthenticated && secureChannel) {
      sendAuthDataToExtension();
    }
  }, [
    authState.isAuthenticated,
    secureChannel,
    sendAuthDataToExtension,
    isElectron,
  ]);

  const establishSecureChannel = useCallback(async () => {
    if (isElectron) {
      logger('Skipping secure channel establishment in Electron environment');
      return;
    }

    if (!authState.isAuthenticated) {
      logger('User not authenticated. Skipping secure channel establishment.');
      return;
    }

    if (secureChannel) {
      logger('Secure channel already established.');
      return;
    }

    logger('Attempting to establish secure channel...');
    try {
      const extensionId = await attemptConnection();
      logger('Received extension ID:', extensionId);

      const challengeResponse = await getChallenge(extensionId);
      if (
        !challengeResponse ||
        !challengeResponse.challenge ||
        typeof challengeResponse.challenge !== 'string'
      ) {
        throw new Error(
          `Invalid challenge received: ${JSON.stringify(challengeResponse)}`
        );
      }

      window.postMessage(
        { type: 'FLUENCY_CHALLENGE', challenge: challengeResponse.challenge },
        '*'
      );

      const response = await new Promise<any>((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          window.removeEventListener('message', messageHandler);
          reject(new Error('Timeout: Challenge response not received'));
        }, 1500);

        const messageHandler = (event: MessageEvent) => {
          if (event.data.type === 'FLUENCY_CHALLENGE_RESPONSE') {
            clearTimeout(timeoutId);
            window.removeEventListener('message', messageHandler);
            resolve(event.data);
          }
        };

        window.addEventListener('message', messageHandler);
      });

      const verification = await verifyChallenge(
        response.extensionId,
        response.response,
        response.signature
      );

      if (verification.success) {
        logger('Secure channel established with extension', { extensionId });
        setSecureChannel(extensionId);
        sendAuthDataToExtension();
      } else {
        throw new Error('Challenge verification failed');
      }
    } catch (error) {
      logger('Error establishing secure channel:', error);
      setSecureChannel(null);
    }
  }, [authState.isAuthenticated, logger, isElectron]);

  const refreshAccessToken = useCallback(async () => {
    logger('Attempting to refresh access token');
    const storedRefreshToken = getStoredRefreshToken();
    if (storedRefreshToken) {
      try {
        const response = await refreshTokens(storedRefreshToken);
        handleAuthSuccess(response);
      } catch (error) {
        logger('Error refreshing token:', error);
        logoutHandler();
      }
    } else {
      logger('No refresh token available for token refresh');
      logoutHandler();
    }
  }, [logger]);

  const scheduleTokenRefresh = useCallback(
    (expirationTime: number) => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiry = expirationTime - currentTime;
      const refreshTime = Math.max(timeUntilExpiry - 300, 0); // Refresh 5 minutes before expiry or immediately

      // Convert seconds to days, hours, minutes, and seconds
      const days = Math.floor(refreshTime / (24 * 60 * 60));
      const hours = Math.floor((refreshTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((refreshTime % (60 * 60)) / 60);
      const seconds = refreshTime % 60;

      logger(
        `Scheduling token refresh in ${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds`
      );
      refreshTimeoutRef.current = setTimeout(
        refreshAccessToken,
        refreshTime * 1000
      );
    },
    [refreshAccessToken, logger]
  );

  const handleAuthSuccess = useCallback(
    async (response: WorkOSResponse) => {
      logger('Authentication successful', {
        userId: response.user.id,
        organisationId: response.organizationId,
      });

      const decoded = decodeJWT(response.accessToken);
      setStoredAccessToken(response.accessToken);
      setStoredRefreshToken(response.refreshToken);

      let newAuthState: Partial<AuthContextType> = {
        isAuthenticated: true,
        user: response.user,
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        decodedToken: decoded,
        orgId: decoded.org_id || response.organizationId || null,
        sessionId: decoded.sid || null,
        organisationName: '',
        organisationResponse: null,
        workOSResponse: response,
        subscription: null,
        isLoading: false,
        hasCompletedOrgForm: false,
      };

      let shouldBroadcast = false;

      if (decoded.org_id) {
        try {
          const organisation = await getOrganisation(response.accessToken);
          newAuthState.organisationName = organisation?.workos?.name || '';
          newAuthState.organisationResponse = organisation;
          newAuthState.hasCompletedOrgForm = true;
          setStoredOrganisationId(organisation.workos.id);

          const subscriptionStatus = await getSubscriptionStatus(
            response.accessToken
          );
          newAuthState.subscription = subscriptionStatus?.status || null;

          // Only set shouldBroadcast to true if both orgId and subscription exist
          shouldBroadcast = !!newAuthState.orgId && !!newAuthState.subscription;
        } catch (error) {
          logger('Error fetching additional user info:', error);
          if (error instanceof Error) {
            logger('Error details:', {
              message: error.message,
              stack: error.stack,
            });
          }
        }
      } else {
        logger('New user detected. Organization creation flow required.');
      }
      updateAuthState(newAuthState);
      scheduleTokenRefresh(decoded.exp);
      if (shouldBroadcast) {
        broadcastLogin(response.user);
      }

      if (secureChannel) {
        notifyExtension({
          token: newAuthState.accessToken,
          user: newAuthState.user,
          role: newAuthState.decodedToken?.role || null,
          permissions: newAuthState.decodedToken?.permissions || [],
          organisation: {
            id: newAuthState.orgId || null,
            name: newAuthState.organisationName,
          },
          subscription: newAuthState.subscription,
        });
      }
    },
    [
      logger,
      updateAuthState,
      scheduleTokenRefresh,
      notifyExtension,
      secureChannel,
      broadcastLogin,
    ]
  );

  const refreshTokenWithOrgId = useCallback(
    async (organizationId: string) => {
      logger('Refreshing token with new organization ID');
      const currentRefreshToken = authState.refreshToken;
      if (!currentRefreshToken) {
        throw new Error('No refresh token available');
      }

      try {
        const freshTokens = await refreshTokens(
          currentRefreshToken,
          organizationId
        );
        await handleAuthSuccess(freshTokens);
        logger('Token refreshed successfully with new organization ID');
        return freshTokens; // Return the fresh tokens
      } catch (error) {
        logger('Error refreshing token with organization ID:', error);
        throw error;
      }
    },
    [authState.refreshToken, handleAuthSuccess, logger]
  );

  const handleAuthError = useCallback(
    (error: Error) => {
      logger('Authentication error:', error);
      logoutHandler();
    },
    [logger]
  );

  const logoutHandler = useCallback(async () => {
    if (isLoggingOut) return;

    setIsLoggingOut(true);

    logger('Initiating logout process');
    updateAuthState({ isLoading: true });

    try {
      // Clear stored tokens immediately
      clearStoredTokens();

      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      // Prepare the auth state reset
      const resetState = {
        isAuthenticated: false,
        user: null,
        accessToken: null,
        refreshToken: null,
        orgId: null,
        decodedToken: null,
        organisationName: '',
        organisationResponse: null,
        justLoggedOut: true,
        subscription: null,
        isLoading: false,
      };
      // Notify extension
      notifyExtension({
        token: null,
        user: null,
        role: null,
        permissions: [],
        organisation: { id: null, name: null },
        subscription: null,
      });

      setSecureChannel(null);

      broadcastLogout();

      // Perform WorkOS logout if session ID is available
      if (authState.decodedToken?.sid) {
        logger('SessionId found, performing WorkOS logout');
        const logoutUrl = await logoutUtil(authState.decodedToken.sid);

        // Update auth state before redirecting
        updateAuthState(resetState);

        logger('Redirecting to WorkOS logout URL');
        window.location.href = logoutUrl;
      } else {
        logger('No sessionId available, performing local logout only');
        // Update auth state
        updateAuthState(resetState);
        // Redirect to login page for local logout
        window.location.href = '/login';
      }
    } catch (error) {
      logger('Logout error:', error);
      // Even if there's an error, we should still clear local state
      updateAuthState({
        isAuthenticated: false,
        user: null,
        accessToken: null,
        refreshToken: null,
        orgId: null,
        decodedToken: null,
        organisationName: '',
        organisationResponse: null,
        justLoggedOut: true,
        subscription: null,
        isLoading: false,
      });
      window.location.href = '/login';
    }
  }, [
    logger,
    updateAuthState,
    notifyExtension,
    authState.decodedToken,
    broadcastLogout,
    isLoggingOut,
  ]);

  const login = useCallback(async () => {
    logger('Initiating login process');
    updateAuthState({ isLoading: true, authError: null });
    try {
      await loginUtil();
      logger('Login process completed successfully');
    } catch (error) {
      logger('Login error:', error);
      updateAuthState({
        authError:
          error instanceof Error
            ? error.message
            : 'An unknown error occurred during login',
      });
    } finally {
      updateAuthState({ isLoading: false });
    }
  }, [logger, updateAuthState]);

  const handleManualTokenLogin = useCallback(
    async (refreshToken: string) => {
      logger('Initiating manual token login');
      updateAuthState({ isLoading: true, authError: null });
      try {
        const freshTokens = await refreshTokens(refreshToken);
        const user = await getUserProfile(freshTokens.accessToken);
        const decodedToken = decodeJWT(freshTokens.accessToken);

        const fullResponse: WorkOSResponse = {
          ...freshTokens,
          user,
          decodedToken,
          organizationId: decodedToken.org_id || undefined,
        };

        await handleAuthSuccess(fullResponse);
      } catch (error) {
        logger('Error handling manual token login:', error);
        updateAuthState({
          authError: 'Failed to authenticate. Please try again.',
          isLoading: false,
        });
        handleAuthError(
          error instanceof Error ? error : new Error('Unknown error occurred')
        );
      }
    },
    [logger, updateAuthState, handleAuthSuccess, handleAuthError]
  );

  const initialiseAuthState = useCallback(
    async (force = false) => {
      if (initialisationInProgress.current && !force) {
        logger('Initialization already in progress, skipping');
        return;
      }

      if (initialisationTimeout.current) {
        clearTimeout(initialisationTimeout.current);
      }

      initialisationInProgress.current = true;
      logger('Starting auth state initialization');

      try {
        const storedAccessToken = getStoredAccessToken();
        const storedRefreshToken = getStoredRefreshToken();

        if (storedAccessToken) {
          const decoded = decodeJWT(storedAccessToken);
          if (decoded && decoded.exp && decoded.exp > Date.now() / 1000) {
            try {
              const user = await getUserProfile(storedAccessToken);
              let newAuthState: Partial<AuthContextType> = {
                isAuthenticated: true,
                user,
                accessToken: storedAccessToken,
                refreshToken: storedRefreshToken,
                decodedToken: decoded,
                orgId: decoded.org_id || null,
                organisationName: '',
                organisationResponse: null,
                subscription: null,
                isLoading: false,
                hasCompletedOrgForm: false,
              };

              if (decoded.org_id) {
                try {
                  const organisation = await getOrganisation(storedAccessToken);
                  newAuthState.organisationName =
                    organisation?.workos?.name || '';
                  newAuthState.organisationResponse = organisation;
                  newAuthState.hasCompletedOrgForm = true;

                  const subscriptionStatus = await getSubscriptionStatus(
                    storedAccessToken
                  );
                  newAuthState.subscription =
                    subscriptionStatus?.status || null;
                } catch (error) {
                  logger('Error fetching additional user info:', error);
                  if (error instanceof Error) {
                    logger('Error details:', {
                      message: error.message,
                      stack: error.stack,
                    });
                  }
                }
              } else {
                logger(
                  'New user detected. Organization creation flow required.'
                );
              }

              updateAuthState(newAuthState);
              scheduleTokenRefresh(decoded.exp);
              await establishSecureChannel();
            } catch (error) {
              logger('Error initializing auth state:', error);
              if (error instanceof Error) {
                logger('Error details:', {
                  message: error.message,
                  stack: error.stack,
                });
              }
              updateAuthState({
                authError: 'Initialization failed, will retry',
                isLoading: false,
              });
            }
          } else if (storedRefreshToken) {
            await handleManualTokenLogin(storedRefreshToken);
          } else {
            updateAuthState({ isAuthenticated: false, isLoading: false });
          }
        } else if (storedRefreshToken) {
          await handleManualTokenLogin(storedRefreshToken);
        } else {
          updateAuthState({ isAuthenticated: false, isLoading: false });
        }
      } catch (error) {
        logger('Error during initialization:', error);
        updateAuthState({
          authError: 'Initialization failed',
          isLoading: false,
          isAuthenticated: false,
        });
      } finally {
        initialisationInProgress.current = false;
        logger('Auth state initialization completed');
      }
    },
    [
      updateAuthState,
      scheduleTokenRefresh,
      establishSecureChannel,
      handleManualTokenLogin,
      logger,
    ]
  );

  const handleLoginFromOtherTab = useCallback(async () => {
    logger('Handling login from another tab');
    updateAuthState({ isLoading: true });
    try {
      await initialiseAuthState(true);
      logger('Auth state synchronized after login from another tab');
    } catch (error) {
      logger(
        'Error synchronizing auth state after login from another tab:',
        error
      );
    } finally {
      updateAuthState({ isLoading: false });
    }
  }, [initialiseAuthState, updateAuthState, logger]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'auth_broadcast') {
        try {
          const data = JSON.parse(e.newValue || '');
          if (data.type === 'LOGOUT') {
            logger('Logout event received from another tab');
            logoutHandler();
          } else if (data.type === 'LOGIN') {
            logger('Login event received from another tab');
            handleLoginFromOtherTab();
          }
        } catch (error) {
          logger('Error parsing auth broadcast:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [logoutHandler, handleLoginFromOtherTab, logger]);

  useEffect(() => {
    const initialise = async () => {
      if (!isInitialised) {
        updateAuthState({ isLoading: true });
        await initialiseAuthState();
        setisInitialised(true);
      }
    };
    initialise();
  }, [isInitialised, initialiseAuthState]);

  useEffect(() => {
    let retryTimeout: NodeJS.Timeout;

    if (authState.authError && authState.authError.includes('will retry')) {
      retryTimeout = setTimeout(() => {
        logger('Retrying initialization');
        initialiseAuthState();
      }, 2000); // Retry after 2 seconds
    }

    return () => {
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [authState.authError, initialiseAuthState]);

  // Call it after authentication
  useEffect(() => {
    const setupSecureChannel = async () => {
      if (authState.isAuthenticated && !secureChannel) {
        await establishSecureChannel();
      }
    };
    setupSecureChannel();
  }, [authState.isAuthenticated, secureChannel, establishSecureChannel]);

  // Check if user is new and needs to complete org form
  const needsToCompleteOrgForm = useCallback(() => {
    return (
      authState.isAuthenticated &&
      (!authState.hasCompletedOrgForm || !authState.orgId)
    );
  }, [
    authState.isAuthenticated,
    authState.hasCompletedOrgForm,
    authState.orgId,
  ]);

  const setOrgFormDataAndUpdateState = useCallback(
    (data: OrgFormSubmittedData) => {
      setOrgFormData(data);
      updateAuthState({ hasCompletedOrgForm: true });
      setTemporaryPermission('subscription:update');
    },
    [updateAuthState, setTemporaryPermission]
  );

  const updateSubscriptionStatus = useCallback(
    async (token: string) => {
      try {
        const subscriptionStatus = await getSubscriptionStatus(token);
        updateAuthState({ subscription: subscriptionStatus?.status || null });
        logger('Subscription status updated', {
          status: subscriptionStatus?.status,
        });
      } catch (error) {
        logger('Error fetching subscription status:', error);
      }
    },
    [updateAuthState, logger]
  );

  useEffect(() => {
    const handleExtensionInstalled = (event: MessageEvent) => {
      if (event.data.type === 'EXTENSION_INSTALLED') {
        logger('Extension installed or reloaded');
        establishSecureChannel();
      }
    };

    window.addEventListener('message', handleExtensionInstalled);

    return () => {
      window.removeEventListener('message', handleExtensionInstalled);
    };
  }, [establishSecureChannel, logger]);

  const handleDeepLink = useCallback(
    async (url: string) => {
      updateAuthState({ isLoading: true });
      try {
        const urlObj = new URL(url);
        const token = urlObj.searchParams.get('token');

        if (!token) {
          throw new Error('No token found in the URL');
        }

        await handleManualTokenLogin(token);
      } catch (error) {
        console.error('Error handling deep link:', error);
        handleAuthError(
          error instanceof Error ? error : new Error('Unknown error occurred')
        );
      } finally {
        updateAuthState({ isLoading: false });
      }
    },
    [handleManualTokenLogin, handleAuthError, updateAuthState]
  );

  const getLoginUrl = useCallback(async () => {
    logger('Getting login URL');
    try {
      const authUrl = await loginUtil(false);
      return authUrl as string;
    } catch (error) {
      logger('Error getting login URL:', error);
      throw error;
    }
  }, [logger]);

  useEffect(() => {
    logger('Setting up event listeners');
    window.electron?.on('deep-link-url', handleDeepLink);

    return () => {
      logger('Cleaning up event listeners and intervals');
      if (window.electron?.removeListener) {
        window.electron.removeListener('deep-link-url', handleDeepLink);
      }
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [handleDeepLink, logger]);

  const contextValue: AuthContextType = {
    isLoading: authState.isLoading,
    isAuthenticated: authState.isAuthenticated,
    isInitialised,
    user: authState.user,
    accessToken: authState.accessToken,
    refreshToken: authState.refreshToken,
    decodedToken: authState.decodedToken,
    orgId: authState.orgId,
    organisationName: authState.organisationName,
    organisationResponse: authState.organisationResponse,
    workOSResponse: authState.workOSResponse,
    justLoggedOut: authState.justLoggedOut,
    authError: authState.authError,
    subscription: authState.subscription,
    hasCompletedOrgForm: authState.hasCompletedOrgForm,
    orgFormData,
    role: authState.decodedToken?.role || null,
    permissions: [
      ...(authState.decodedToken?.permissions || []),
      ...temporaryPermissions,
    ],
    sessionId: authState.decodedToken?.sid || null,
    isLoggingOut,

    login,
    getLoginUrl,
    logout: logoutHandler,
    handleManualTokenLogin,
    redirectToLogin: useCallback(() => {
      logger('Redirecting to login page');
      window.location.href = '/login';
    }, [logger]),
    setHasCompletedOrgForm: (value: boolean) =>
      updateAuthState({ hasCompletedOrgForm: value }),
    setOrgFormData: setOrgFormDataAndUpdateState,
    needsToCompleteOrgForm,
    updateSubscriptionStatus,
    refreshTokenWithOrgId,
    secureChannel,
    establishSecureChannel: isElectron ? undefined : establishSecureChannel,
    sendAuthDataToExtension,
    setIsAuthenticated: (value: boolean) =>
      updateAuthState({ isAuthenticated: value }),
    setUser: (user: WorkOSUser | null) => updateAuthState({ user }),
    setAccessToken: (token: string | null) =>
      updateAuthState({ accessToken: token }),
    setRefreshToken: (token: string | null) =>
      updateAuthState({ refreshToken: token }),
    setOrgId: (id: string | null) => updateAuthState({ orgId: id }),
    setOrganisationName: (name: string) =>
      updateAuthState({ organisationName: name }),
    setWorkOSResponse: (response: WorkOSResponse | null) =>
      updateAuthState({ workOSResponse: response }),
    setJustLoggedOut: (value: boolean) =>
      updateAuthState({ justLoggedOut: value }),
    setAuthError: (error: string | null) =>
      updateAuthState({ authError: error }),
    setSubscription: (status: string | null) =>
      updateAuthState({ subscription: status }),
    fetchAdditionalInfo: useCallback(
      async (token: string) => {
        logger('Fetching additional organization information');
        try {
          const organisation = await getOrganisation(token);
          updateAuthState({
            organisationName: organisation.workos.name,
            orgId: organisation.workos.id,
            organisationResponse: organisation,
          });
          setStoredOrganisationId(organisation.workos.id);
          logger('Organization information fetched successfully', {
            organisationName: organisation.workos.name,
            organisationId: organisation.workos.id,
          });
        } catch (error) {
          logger('Error fetching organisation:', error);
          updateAuthState({
            organisationName: '',
            orgId: null,
            organisationResponse: null,
          });
        }
      },
      [logger, updateAuthState]
    ),
    setTemporaryPermission,
    clearTemporaryPermissions,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const attemptConnection = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      window.removeEventListener('message', messageHandler);
      reject(new Error('Timeout: Extension not responding'));
    }, 1500);

    const messageHandler = (event: MessageEvent) => {
      if (event.source !== window) return;

      if (event.data.type === 'FLUENCY_EXTENSION_ID') {
        clearTimeout(timeoutId);
        window.removeEventListener('message', messageHandler);

        if (typeof event.data.extensionId === 'string') {
          resolve(event.data.extensionId);
        } else {
          reject(new Error('Invalid extension ID received'));
        }
      }
    };

    window.addEventListener('message', messageHandler);
    window.postMessage({ type: 'FLUENCY_GET_EXTENSION_ID' }, '*');
  });
};
