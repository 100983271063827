import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Trash2 } from 'lucide-react';
import { Input } from '@fluency/ui/components/ui/input';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';

export default withPermissionGate(DeleteOrgModal, ['org:delete']);

const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

function DeleteOrgModal() {
  const navigate = useNavigate();
  const { logout, accessToken } = useAuth();
  const [open, setOpen] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const handleDeleteOrg = async () => {
    if (isConfirmingDelete && confirmText === 'DELETE') {
      try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
        const requestOptions: RequestInit = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow' as RequestRedirect,
        };

        const response = await fetch(
          `${apiBaseUrl}/organisation`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        await response.text();
        await logout();
        navigate({ to: '/login', replace: true });
      } catch (error) {
        console.error('Error deleting organization:', error);
        toast({ title: 'Error deleting organization' });
      }
    } else {
      setIsConfirmingDelete(true);
    }
  };

  const cancelDelete = () => {
    setIsConfirmingDelete(false);
    setConfirmText('');
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="link" className="w-fit text-red-600 gap-2 px-0">
          <Trash2 className="h-5 w-5 hover:cursor-pointer" /> Delete
          Organisation
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogFooter>
          <div className="w-full">
            <span className="font-semibold text-red-600">
              Delete Organisation
            </span>
            <p className="text-sm text-red-600 mb-4">
              Deleting the organisation will permanently remove all associated
              data. This action cannot be undone.
            </p>
            {!isConfirmingDelete ? (
              <Button
                variant="destructive"
                onClick={handleDeleteOrg}
                className="w-full"
              >
                Delete Organisation
              </Button>
            ) : (
              <>
                <p className="text-sm mb-2">Type DELETE to confirm:</p>
                <Input
                  type="text"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  className="mb-4"
                />
                <div className="flex gap-2">
                  <Button
                    variant="destructive"
                    onClick={handleDeleteOrg}
                    className="flex-1"
                    disabled={confirmText !== 'DELETE'}
                  >
                    Confirm Delete
                  </Button>
                  <Button
                    variant="outline"
                    onClick={cancelDelete}
                    className="flex-1"
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
