import { useState, useCallback } from 'react';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { SyncStatus, ExtensionStatus } from '../types.js';

export const useSyncStatus = (
  extensionStatus: ExtensionStatus,
  checkExtensionStatus: () => Promise<void>
) => {
  const [syncStatus, setSyncStatus] = useState<SyncStatus>('idle');
  const { sendAuthDataToExtension } = useAuth();

  const sendAuthData = useCallback(async () => {
    console.log('Syncing extension, current status:', extensionStatus);
    setSyncStatus('syncing');

    try {
      if (sendAuthDataToExtension) {
        // Add a delay to show the spinning animation
        await new Promise((resolve) => setTimeout(resolve, 1500));

        sendAuthDataToExtension();
        console.log('Auth data sent successfully');
        await checkExtensionStatus();

        if (extensionStatus === 'installed_not_connected') {
          throw new Error('Extension is installed but not connected');
        }

        setSyncStatus('success');
      } else {
        throw new Error('sendAuthDataToExtension is not available');
      }
    } catch (error) {
      console.log('Error sending auth data:', error);
      setSyncStatus('failed');
    }

    // Keep the status visible for a longer time
    setTimeout(() => setSyncStatus('idle'), 5000);
  }, [extensionStatus, sendAuthDataToExtension, checkExtensionStatus]);

  return { syncStatus, sendAuthData };
};
