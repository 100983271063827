import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_API_URL,
});

export const verifyChallenge = async (
  extensionId: string,
  response: string,
  signature: string
) => {
  try {
    const verificationResponse = await api.post('/auth/verifyChallenge', {
      extensionId,
      response,
      signature,
    });
    return verificationResponse.data;
  } catch (error) {
    console.error('Error verifying challenge:', error);
    throw error;
  }
};
