import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { useSubscription } from '@fluency/ui/hooks';
import { useBillingDetails } from '@fluency/ui/hooks/useBillingDetails';
import type { BillingDetailsData } from '@fluency/ui/hooks/useBillingDetails';

const createFieldSchema = (
  minLength = 1,
  maxLength: number,
  isRequired = true
): z.ZodString | z.ZodOptional<z.ZodString> => {
  let schema = z
    .string()
    .max(maxLength, { message: `Must be ${maxLength} characters or less.` });
  if (isRequired) {
    schema = schema.min(minLength, {
      message: `Must be at least ${minLength} characters.`,
    });
  }
  return isRequired ? schema : schema.optional();
};

const emailSchema = z
  .string()
  .email({ message: 'Invalid email address.' })
  .max(254, { message: 'Must be 254 characters or less.' });

const australianPostcodeSchema = z.string().regex(/^\d{4}$/, {
  message: 'Must be a valid 4-digit Australian postcode.',
});

const australianStates = [
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
] as const;
type AustralianState = (typeof australianStates)[number];

const FormSchema = z.object({
  email: emailSchema,
  name: createFieldSchema(2, 100),
  phone: createFieldSchema(1, 20, false),
  address: z.object({
    line1: createFieldSchema(1, 100),
    line2: createFieldSchema(1, 100, false),
    city: createFieldSchema(1, 50),
    state: z.enum(australianStates),
    postalCode: australianPostcodeSchema,
    country: z.literal('AU'),
  }),
  taxId: createFieldSchema(11, 11, false),
  taxIdType: z.literal('au_abn'),
});

type FormSchemaType = z.infer<typeof FormSchema>;

export default function BillingDetailsModal() {
  const { billingDetails, refetchBilling } = useSubscription();
  const { updateBillingDetails, isLoading } = useBillingDetails();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: billingDetails?.email || '',
      name: billingDetails?.name || '',
      phone: billingDetails?.phone || '',
      address: {
        line1: billingDetails?.address.line1 || '',
        line2: billingDetails?.address.line2 || '',
        city: billingDetails?.address.city || '',
        state: (billingDetails?.address.state as AustralianState) || 'NSW',
        postalCode: billingDetails?.address.postalCode || '',
        country: 'AU',
      },
      taxId: billingDetails?.taxId || '',
      taxIdType: 'au_abn',
    },
  });

  const onSubmit = async (data: FormSchemaType) => {
    const billingData: BillingDetailsData = {
      email: data.email,
      name: data.name || '',
      phone: data.phone || undefined,
      address: {
        line1: data.address.line1 || '',
        line2: data.address.line2 || undefined,
        city: data.address.city || '',
        state: data.address.state,
        postalCode: data.address.postalCode,
        country: data.address.country,
      },
      taxIdValue: data.taxId || undefined,
      taxIdType: data.taxIdType,
    };
    const success = await updateBillingDetails(billingData);
    if (success) await refetchBilling();
  };

  const renderFormField = (
    name: keyof FormSchemaType | `address.${keyof FormSchemaType['address']}`,
    label: string,
    type = 'text',
    placeholder = ''
  ) => (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              {...field}
              type={type}
              placeholder={placeholder}
              maxLength={100}
              value={field.value?.toString() || ''}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="secondary" className="h-8">
          Edit
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Edit Australian Billing Details</DialogTitle>
          <DialogDescription>
            Make changes to your Australian billing information here. Click save
            when you're done.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {renderFormField('name', 'Name')}
              {renderFormField('email', 'Email', 'email')}
              {renderFormField('phone', 'Phone')}
              {renderFormField('taxId', 'ABN (optional)', 'text', '11 digits')}
            </div>
            {renderFormField('address.line1', 'Address Line 1')}
            {renderFormField('address.line2', 'Address Line 2 (optional)')}
            <div className="grid grid-cols-2 gap-4">
              {renderFormField('address.city', 'City')}
              <FormField
                control={form.control}
                name="address.state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <select {...field} className="w-full p-2 border rounded">
                        {australianStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {renderFormField(
                'address.postalCode',
                'Postcode',
                'text',
                '4 digits'
              )}
              <FormField
                control={form.control}
                name="address.country"
                render={() => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Input value="AU" disabled className="bg-gray-100" />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            type="submit"
            disabled={isLoading || !billingDetails}
            onClick={form.handleSubmit(onSubmit)}
          >
            {isLoading ? 'Saving...' : 'Save changes'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
