import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';

interface OrgImageResponse {
  message: string;
  downloadUrl: string;
}

export const useOrgImage = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { accessToken } = useAuth();

  const getOrgImageQuery = useQuery<OrgImageResponse, Error>({
    queryKey: ['orgImage'],
    queryFn: async () => {
      const response = await fetch(`${apiBaseUrl}/organisation/companyLogo`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch organization image');
      }

      return response.json();
    },
  });

  const uploadOrgImageMutation = useMutation<string, Error, File>({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      // formData.append('fileType', file.type.split('/')[1]);
      formData.append('fileType', file.type.split('/')[1] ?? 'unknown');
      formData.append('fileBuffer', file);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${apiBaseUrl}/organisation/uploadCompanyLogo`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setUploadProgress(percentComplete);
        }
      };

      return new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status === 201) {
            resolve(xhr.responseText);
          } else {
            reject(new Error('Upload failed'));
          }
        };

        xhr.onerror = () => {
          reject(new Error('Network error'));
        };

        xhr.send(formData);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orgImage'] });
      setUploadProgress(0);
    },
    onError: (error) => {
      console.error('Upload error:', error);
      setUploadProgress(0);
    },
  });

  return {
    orgImageUrl: getOrgImageQuery.data?.downloadUrl,
    isLoadingImage: getOrgImageQuery.isLoading,
    imageError: getOrgImageQuery.error,
    uploadImage: uploadOrgImageMutation.mutate,
    isUploading: uploadOrgImageMutation.isPending,
    uploadProgress,
    uploadError: uploadOrgImageMutation.error,
  };
};
