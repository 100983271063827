import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_API_URL,
});

export const getChallenge = async (extensionId: string) => {
  try {
    const response = await api.get('/auth/challenge', {
      params: { extensionId },
    });

    if (typeof response.data.challenge !== 'string') {
      throw new Error('Invalid challenge format received from server');
    }

    return response.data;
  } catch (error) {
    console.error('Error getting challenge:', error);
    throw error;
  }
};
