import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@fluency/ui/components/ui/alert';
import { AlertTriangle } from 'lucide-react';
import TransferOwnership from './TransferOwnership.js';
import DeleteOrganisation from './DeleteOrganisation.js';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import ChangeOrgName from './ChangeOrgName.js';

export default withPermissionGate(AdvancedSettings, ['org:update']);

function AdvancedSettings() {
  return (
    <Card className="border-0">
      <CardHeader className="pt-0 gap-2">
        <CardTitle>Settings</CardTitle>
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Warning!</AlertTitle>
          <AlertDescription>
            These actions can have serious consequences, and will impact all
            users within the organisation. Proceed with caution.
          </AlertDescription>
        </Alert>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4">
          <ChangeOrgName />
          <TransferOwnership />
          <DeleteOrganisation />
        </div>
      </CardContent>
    </Card>
  );
}
