import * as Sentry from '@sentry/react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DocumentationsResponse } from './types/index.js';
import { useAuth } from '../providers/auth/AuthProvider.js';

const useGetDocs = (
  page: number = 1,
  pageSize: number = 200
): UseQueryResult<DocumentationsResponse, Error> => {
  const { accessToken } = useAuth();

  return useQuery<DocumentationsResponse, Error>({
    queryKey: ['documentation', page, pageSize],
    queryFn: async (): Promise<DocumentationsResponse> => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const response = await fetch(
        `${apiBaseUrl}/documents/list?pageSize=${pageSize}&page=${page}`,
        requestOptions
      );

      if (!response.ok) {
        Sentry.captureException('Failed to get documentation');
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result as DocumentationsResponse;
    },
  });
};

export default useGetDocs;
