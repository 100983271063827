import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@fluency/ui/components/ui/alert-dialog';
import { DoorOpenIcon, LogOut } from 'lucide-react';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';

export default function Logout() {
  const { logout, isLoggingOut } = useAuth();

  const handleLogout = async () => {
    if (!isLoggingOut) {
      await logout();
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <div className="w-full flex justify-between items-center">
          Logout <DoorOpenIcon className="text-gray-400 h-5" />
        </div>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <div className="flex items-center">
              <LogOut className="mr-2" />
              Sign out
            </div>
          </AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to sign out of your account?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-400 text-white flex items-center"
            onClick={handleLogout}
            disabled={isLoggingOut}
          >
            {isLoggingOut ? 'Signing out...' : 'Yes, sign out'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
