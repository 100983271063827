import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useQuery } from '@tanstack/react-query';
import { PiiRedactedEntities } from '../../../types';

export const useGetOrgPiiEntities = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();

  return useQuery<PiiRedactedEntities, Error>({
    queryKey: ['orgPiiEntities'],
    queryFn: async () => {
      const response = await fetch(`${apiBaseUrl}/pii/orgPiiEntities`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to get orgs PII entities');
      }

      const data = await response.json();
      return data.entities;
    },
  });
};
