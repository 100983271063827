import { createFileRoute, useParams } from '@tanstack/react-router';
import { UserProfilePage } from '@fluency/ui/features';
import { RolePermissionHandler } from '@fluency/ui/features/AuthGuard/RolePermissionHandler';

const Page = () => {
  const { setting } = useParams({ from: '/profile/$setting' });
  return <UserProfilePage setting={setting} />;
};

export const Route = createFileRoute('/profile/$setting')({
  component: () => (
    <RolePermissionHandler requiredPermissions={['user:read']}>
      <Page />
    </RolePermissionHandler>
  ),
});
