import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@fluency/ui/components/ui/card';
import { format } from 'date-fns';
import { useSubscription } from '@fluency/ui/hooks';
import ManageSubscription from './ManageSubscription.js';

export function Plan() {
  const { subscriptionStatus, isLoadingSubscription, subscriptionError } =
    useSubscription();

  if (isLoadingSubscription) {
    return <div>Loading...</div>;
  }

  if (subscriptionError) {
    return <div>Error: {subscriptionError.message}</div>;
  }

  if (!subscriptionStatus) {
    return <div>No subscription data available</div>;
  }

  const { subscription, billing } = subscriptionStatus;

  const formatPrice = (
    amount: number | undefined,
    currency: string | undefined
  ) => {
    if (amount === undefined || currency === undefined) {
      return 'N/A';
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
    }).format(amount / 100);
  };

  return (
    <Card
      className="w-full flex flex-col h-full"
      style={{ minHeight: '400px' }}
    >
      <CardHeader>
        <CardTitle className="text-xl font-semibold">Subscription</CardTitle>
        <CardDescription>
          {billing &&
            billing.nextBillingAmount !== undefined &&
            billing.currency &&
            billing.nextBillingDate &&
            `Next bill: ${formatPrice(
              billing.nextBillingAmount,
              billing.currency
            )} on ${format(new Date(billing.nextBillingDate), 'dd MMMM yyyy')}`}
        </CardDescription>
      </CardHeader>
      <CardContent className="px-6 flex-grow">
        <div className="flex flex-col space-y-4">
          {subscription.products.map((product) => (
            <div key={product.id} className="flex justify-between items-center">
              <div>
                <h3 className="font-medium">{product.name}</h3>
                <p className="text-sm text-gray-500">
                  {product.quantity} {product.quantity > 1 ? 'seats' : 'seat'}
                </p>
              </div>
              <div className="text-right">
                <p className="font-medium">
                  {product.prices &&
                    product.prices[0] &&
                    formatPrice(
                      product.prices[0].unitAmount * product.quantity,
                      product.prices[0].currency
                    )}
                </p>
                <p className="text-sm text-gray-500">
                  {product.prices?.[0]?.recurring?.interval}ly
                </p>
              </div>
            </div>
          ))}
          <div className="pt-4 border-t">
            <div className="flex justify-between items-center font-semibold">
              <span>Total</span>
              <span>
                {billing &&
                  formatPrice(billing.nextBillingAmount, billing.currency)}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="mt-auto">
        <div className="w-full flex justify-end">
          <ManageSubscription />
        </div>
      </CardFooter>
    </Card>
  );
}
