import React, { useRef } from 'react';
import { useOrgImage } from '../hooks/useOrgImage.js';
import { Button } from '@fluency/ui/components/ui/button';
import { Upload, RefreshCw } from 'lucide-react';
import { Label } from '@fluency/ui/components/ui/label';

export const OrganizationImage: React.FC = () => {
  const {
    orgImageUrl,
    isLoadingImage,
    imageError,
    uploadImage,
    isUploading,
    uploadProgress,
    uploadError,
  } = useOrgImage();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadImage(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Label className="pb-2">Company Logo</Label>
      <div className="flex gap-4 mt-2">
        <div className="flex justify-between border p-6 rounded-lg w-fit">
          {isLoadingImage ? (
            <RefreshCw className="w-12 h-12 animate-spin text-gray-600" />
          ) : imageError ? (
            <p className="text-red-500">
              Error loading image: {imageError.message}
            </p>
          ) : (
            orgImageUrl && (
              <img
                src={orgImageUrl}
                alt="Organization Logo"
                className="w-32 h-32 object-contain"
              />
            )
          )}{' '}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="hidden"
        />
        <Button
          variant="secondary"
          onClick={triggerFileInput}
          disabled={isUploading}
        >
          <Upload className="mr-2 h-4 w-4" />
          {isUploading
            ? `Uploading... ${uploadProgress.toFixed(0)}%`
            : 'Upload New Logo'}
        </Button>
        {uploadError && (
          <p className="text-red-500">Upload error: {uploadError.message}</p>
        )}
      </div>
    </>
  );
};
