import { Badge } from '@fluency/ui/components/ui/badge';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { useParams } from '@tanstack/react-router';
import {
  BugIcon,
  CalendarDays,
  Clock,
  FileText,
  RefreshCw,
  User2,
} from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useGetPublicDocs } from '../hooks/index.js';
import type {
  Screenshot,
  SharedDocument,
  Step,
} from '../hooks/useGetPublicDocs.js';
import { getStepTypeIcon, stepTypeColors } from './iconHelper.js';

const Loading: React.FC = () => (
  <div className="flex justify-center items-center mx-auto max-w-7xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 h-[60vh]">
    <RefreshCw className="h-10 w-10 animate-spin text-gray-300" />
  </div>
);

interface ErrorMessageProps {
  error: Error;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => (
  <div className="flex flex-col justify-center items-center mx-auto max-w-7xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-24 space-y-4 h-[60vh]">
    <BugIcon className="h-16 w-16 sm:h-24 sm:w-24 lg:h-28 lg:w-28" />
    <p className="text-sm sm:text-lg lg:text-xl font-semibold">
      Something went wrong. Please try again later.
    </p>
    <p className="text-xs sm:text-sm lg:text-base text-gray-600">
      {error.toString()}
    </p>
  </div>
);

interface DocumentCardProps {
  step: Step;
  index: number;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ step, index }) => {
  const screenshot: Screenshot | undefined = step.screenshots[0];
  const isCropped =
    screenshot?.xCoordinate != null &&
    screenshot?.yCoordinate != null &&
    typeof screenshot.xCoordinate === 'number' &&
    typeof screenshot.yCoordinate === 'number' &&
    (screenshot.xCoordinate !== 0 || screenshot.yCoordinate !== 0);

  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [translate, setTranslate] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const zoom = isCropped ? 2 : 1;

  // Handle initial translation if image is cropped
  useEffect(() => {
    if (!imageRef.current || !containerRef.current || !isCropped) return;

    const imageWidth = imageRef.current.offsetWidth;
    const imageHeight = imageRef.current.offsetHeight;
    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;

    const xPercent = (screenshot?.xCoordinate ?? 0) / 100;
    const yPercent = (screenshot?.yCoordinate ?? 0) / 100;

    const clickX = xPercent * imageWidth;
    const clickY = yPercent * imageHeight;

    const scaledImageWidth = imageWidth * zoom;
    const scaledImageHeight = imageHeight * zoom;

    const scaledClickX = clickX * zoom;
    const scaledClickY = clickY * zoom;

    let translateX = containerWidth / 2 - scaledClickX;
    let translateY = containerHeight / 2 - scaledClickY;

    const maxTranslateX = 0;
    const minTranslateX = containerWidth - scaledImageWidth;

    const maxTranslateY = 0;
    const minTranslateY = containerHeight - scaledImageHeight;

    translateX = Math.max(minTranslateX, Math.min(maxTranslateX, translateX));
    translateY = Math.max(minTranslateY, Math.min(maxTranslateY, translateY));

    setTranslate({ x: translateX, y: translateY });
  }, [zoom, isCropped, screenshot]);

  const stepTypeClass =
    stepTypeColors[step.stepType as keyof typeof stepTypeColors] || '';
  const isStepTypeStep = step.stepType === 'STEP';

  const formatStepType = (stepType: string) => {
    if (!stepType) return '';
    return stepType.charAt(0).toUpperCase() + stepType.slice(1).toLowerCase();
  };

  return (
    <div
      className={`mb-6 ${stepTypeClass} ${
        !isStepTypeStep ? 'p-4 rounded-xl' : ''
      }`}
    >
      <p className="mb-2 flex flex-col gap-1">
        <div className="flex items-center">
          {getStepTypeIcon(step.stepType)}
          <b className="whitespace-nowrap font-semibold">
            {isStepTypeStep
              ? `Step ${index}: `
              : `${formatStepType(step.stepType)}: `}
          </b>
        </div>
        {step.stepDescription}
      </p>
      {screenshot?.downloadUrl && (
        <div className="relative overflow-hidden w-full h-auto">
          <div
            className="relative w-full h-auto"
            ref={containerRef}
            style={{ overflow: 'hidden' }}
          >
            <div
              className="relative w-full h-auto"
              style={{
                transform:
                  zoom !== 1
                    ? `translate(${translate.x}px, ${translate.y}px) scale(${zoom})`
                    : 'none',
                transformOrigin: 'top left',
                transition: 'transform 0.2s ease-in-out',
              }}
            >
              <img
                ref={imageRef}
                className="w-full h-auto object-cover rounded"
                src={screenshot.downloadUrl}
                alt={`${
                  isStepTypeStep
                    ? `Step ${index}`
                    : formatStepType(step.stepType)
                }`}
              />
              {screenshot.annotations && screenshot.annotations.length > 0 && (
                <div className="absolute inset-0 pointer-events-none">
                  {screenshot.annotations
                    .filter(
                      (annotation) =>
                        !(
                          annotation.x1Coordinate === 0 &&
                          annotation.y1Coordinate === 0
                        )
                    )
                    .map((annotation, index) => (
                      <div
                        key={annotation.screenshotAnnotationId}
                        className="absolute"
                        style={{
                          left: `${annotation.x1Coordinate}%`,
                          top: `${annotation.y1Coordinate}%`,
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <svg
                          width="30"
                          height="30"
                          className="overflow-visible"
                        >
                          <circle
                            cx="15"
                            cy="15"
                            r="15"
                            fill={`${annotation.color}26`}
                            stroke={annotation.color}
                            strokeWidth="2"
                          />
                          {screenshot.annotations.length > 1 && (
                            <text
                              x="15"
                              y="15"
                              fill={annotation.color}
                              fontSize="14"
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              {index + 1}
                            </text>
                          )}
                        </svg>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Documents: React.FC = () => {
  const { document } = useParams({ from: '/share/$document' });
  const { data, isLoading, isError, error } = useGetPublicDocs(document);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <ErrorMessage error={error} />;
  }
  if (!data || !data.sharedDocument) {
    return null;
  }

  const sharedDocument: SharedDocument = data.sharedDocument;

  const formattedCreatedDate = sharedDocument.createdDate
    ? new Date(sharedDocument.createdDate).toISOString()
    : '';

  const formatDuration = (duration: number | string) => {
    const durationStr =
      typeof duration === 'number' ? duration.toString() : duration;
    const parts = durationStr.split('.');
    const minutes = parts[0];
    const seconds =
      parts.length > 1
        ? Math.round(parseFloat(`0.${parts[1]}`) * 60).toString()
        : '0';
    return `${minutes} min ${seconds} sec`;
  };

  const formatDate = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const stepTypeSteps = sharedDocument.steps.filter(
    (step) => step.stepType === 'STEP'
  );

  return (
    <div className="max-w-5xl min-w-[200px] mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>{sharedDocument.documentationName}</CardTitle>
          <CardDescription className="text-lg">
            {sharedDocument.description}
            <div className="flex flex-wrap gap-x-2 gap-y-3 mt-6 w-full">
              <Badge variant="secondary">
                <FileText className="h-4 w-4 my-0.5 mr-2" />
                {sharedDocument.totalSteps} Steps
              </Badge>
              <Badge variant="secondary">
                <User2 className="h-4 w-4 my-0.5 mr-2" />
                {sharedDocument.userInfo[0]?.firstName
                  ? `${sharedDocument.userInfo[0].firstName} ${
                      sharedDocument.userInfo[0].lastName?.[0] || ''
                    }`
                  : sharedDocument.userInfo[0]?.email || 'Unknown'}
              </Badge>
              <Badge variant="secondary">
                <Clock className="h-4 w-4 my-0.5 mr-2" />
                {sharedDocument.duration &&
                  formatDuration(sharedDocument.duration)}
              </Badge>
              <Badge variant="secondary">
                <CalendarDays className="h-4 w-4 my-0.5 mr-2" />
                {formattedCreatedDate && (
                  <>
                    <p className="mr-1">Created on</p>
                    <time dateTime={formattedCreatedDate}>
                      {sharedDocument.createdDate &&
                        formatDate(sharedDocument.createdDate)}
                    </time>
                  </>
                )}
              </Badge>
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent>
          {sharedDocument.steps.map((step, _index) => {
            const stepIndex =
              stepTypeSteps.findIndex((s) => s.stepId === step.stepId) + 1;
            return (
              <DocumentCard
                key={step.stepId}
                step={step}
                index={step.stepType === 'STEP' ? stepIndex : 0}
              />
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default Documents;
