import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PiiRedactedEntities } from '../../../types';

type MutationContext = {
  previousPiiData: PiiRedactedEntities | undefined;
};

export const useUpdatePiiRedactionProperties = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    Partial<PiiRedactedEntities>,
    MutationContext
  >({
    mutationFn: async (entities) => {
      const response = await fetch(`${apiBaseUrl}/pii/piiRedactionProperties`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entities }),
      });

      if (!response.ok) {
        throw new Error('Failed to update PII redaction properties');
      }
    },
    onMutate: async (newEntities) => {
      await queryClient.cancelQueries({ queryKey: ['orgPiiEntities'] });

      const previousPiiData = queryClient.getQueryData<PiiRedactedEntities>([
        'orgPiiEntities',
      ]);

      queryClient.setQueryData<PiiRedactedEntities>(
        ['orgPiiEntities'],
        (old) => {
          if (!old) return old;
          return { ...old, ...newEntities };
        }
      );

      return { previousPiiData };
    },
    onError: (_err, _newEntities, context) => {
      queryClient.setQueryData<PiiRedactedEntities>(
        ['orgPiiEntities'],
        context?.previousPiiData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgPiiEntities'] });
    },
  });
};
