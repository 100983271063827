import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { OrganizationImage } from './components/OrganisationImage.js';

function GeneralCard() {
  return (
    <Card className="border-0">
      <CardHeader className="pt-0 m-0">
        <CardTitle>Branding</CardTitle>
        <CardDescription>
          Update your branding settings which reflects in your exports.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <OrganizationImage />
      </CardContent>
    </Card>
  );
}

export default GeneralCard;
