import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@fluency/ui/components/ui/alert-dialog';
import { useDeleteTeam } from '../../hooks/useDeleteTeam.js';
import { Button } from '@fluency/ui/components/ui/button';

export function AlertDialogTeamDelete({ teamId }: { teamId: string }) {
  const { mutate: deleteTeam } = useDeleteTeam();

  const handleDelete = () => {
    deleteTeam({ teamId });
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" size="sm">
          Delete Team
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Team Deletion</AlertDialogTitle>
          <AlertDialogDescription>
            Deleting this team is irreversible. This will permanently remove the
            team and its data from our system.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-600"
            onClick={handleDelete}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
