import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import ChangeSeats from './ChangeSeats.js';
import CancelSubscriptionConfirmation from './CancelSubscription.js';
import useSubscription from '@fluency/ui/hooks/useGetSubscriptionStatus';
import { toast } from '@fluency/ui/components/ui/use-toast.js';

export default function ManageSubscription() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [_changesMade, setChangesMade] = useState(false);

  const { createCustomerPortalSession } = useSubscription();

  const handleCancelSubscription = () => {
    // Implement your cancel subscription logic here
    console.log('Subscription cancelled');
    setShowConfirmation(false);
  };

  const handleSaveChanges = (changes: {
    newSeats: any;
    plan: any;
    product: any;
  }) => {
    console.log('Subscription changes:', changes);
    const { newSeats, plan, product } = changes;
    const isAnnual = plan === 'annual';
    const subscriptionRequest = {
      productName: product,
      isAnnual,
      seats: newSeats,
    };

    createCustomerPortalSession(subscriptionRequest, {
      onSuccess: () => {
        toast({
          title: 'Successfully updated subscription',
          description: 'Your changes will be reflected in the billing screen',
        });
      },
      onError: (error) => {
        console.error('Error opening customer portal:', error);
      },
    });
    setChangesMade(true);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="secondary">Manage subscription</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        {!showConfirmation ? (
          <>
            <DialogHeader>
              <DialogTitle>Manage subscription</DialogTitle>
              <DialogDescription>
                Make changes to your billing information here. Click save when
                you're done.
              </DialogDescription>
            </DialogHeader>
            <ChangeSeats onSaveChanges={handleSaveChanges} />
          </>
        ) : (
          <CancelSubscriptionConfirmation
            onCancel={() => setShowConfirmation(false)}
            onConfirm={handleCancelSubscription}
          />
        )}
        <DialogFooter>
          {!showConfirmation && (
            <div className="flex justify-between w-full -mt-20 mr-36">
              <Button
                variant="outline"
                onClick={() => setShowConfirmation(true)}
              >
                Cancel subscription
              </Button>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
