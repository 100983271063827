const REFRESH_TOKEN_KEY = 'workos_refresh_token';
const ACCESS_TOKEN_KEY = 'workos_access_token';
const ORGANISATION_ID_KEY = 'workos_organisation_id';
const LOGGED_OUT_FLAG_KEY = 'logged_out';

const logger = (message: string, data?: any) => {
  if (import.meta.env.NODE_ENV === 'development') {
    console.log(`[TokenUtils] ${message}`, data ? data : '');
  }
};

export const setLoggedOutFlag = (): void => {
  localStorage.setItem(LOGGED_OUT_FLAG_KEY, 'true');
};

export const clearLoggedOutFlag = (): void => {
  localStorage.removeItem(LOGGED_OUT_FLAG_KEY);
};

export const isLoggedOutFlagSet = (): boolean => {
  return localStorage.getItem(LOGGED_OUT_FLAG_KEY) === 'true';
};

// Refresh Token Functions
export const setStoredRefreshToken = (refreshToken: string | null) => {
  try {
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      logger('Refresh token stored successfully');
    } else {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      logger('Refresh token removed');
    }
  } catch (e) {
    console.error('Error setting refresh token:', e);
    throw e;
  }
};

export const getStoredRefreshToken = () => {
  try {
    const token = localStorage.getItem(REFRESH_TOKEN_KEY);
    logger('Retrieved refresh token', { exists: !!token });
    return token;
  } catch (e) {
    console.error('Error getting refresh token:', e);
    return null;
  }
};

export const clearStoredRefreshToken = () => {
  try {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    logger('Cleared refresh token');
  } catch (e) {
    console.error('Error clearing refresh token:', e);
    throw e;
  }
};

// Access Token Functions
export const setStoredAccessToken = (accessToken: string | null) => {
  try {
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      logger('Access token stored successfully');
    } else {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      logger('Access token removed');
    }
  } catch (e) {
    console.error('Error setting access token:', e);
    throw e;
  }
};

export const getStoredAccessToken = () => {
  try {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    logger('Retrieved access token', { exists: !!token });
    return token;
  } catch (e) {
    console.error('Error getting access token:', e);
    return null;
  }
};

export const clearStoredAccessToken = () => {
  try {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    logger('Cleared access token');
  } catch (e) {
    console.error('Error clearing access token:', e);
    throw e;
  }
};

// Organisation ID Functions
export const setStoredOrganisationId = (organisationId: string) => {
  try {
    if (organisationId) {
      localStorage.setItem(ORGANISATION_ID_KEY, organisationId);
      logger('Organisation ID stored successfully');
    }
  } catch (e) {
    console.error('Error setting organisation ID:', e);
    throw e;
  }
};

export const getStoredOrganisationId = () => {
  try {
    const id = localStorage.getItem(ORGANISATION_ID_KEY);
    logger('Retrieved organisation ID', { exists: !!id });
    return id;
  } catch (e) {
    console.error('Error getting organisation ID:', e);
    return null;
  }
};

export const clearStoredOrganisationId = () => {
  try {
    localStorage.removeItem(ORGANISATION_ID_KEY);
    logger('Cleared organisation ID');
  } catch (e) {
    console.error('Error clearing organisation ID:', e);
    throw e;
  }
};

// Clear all stored tokens
export const clearStoredTokens = () => {
  try {
    clearStoredRefreshToken();
    clearStoredAccessToken();
    clearStoredOrganisationId();
    logger('All stored tokens cleared');
  } catch (e) {
    console.error('Error clearing all stored tokens:', e);
    throw e;
  }
};
