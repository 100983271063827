import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { useState } from 'react';
import { useUpdateOrgName } from '../../hooks/index.js';
import { Check, XIcon } from 'lucide-react';
import { useSubscription } from '@fluency/ui/hooks';

const FormSchema = z.object({
  orgName: z
    .string()
    .min(5, {
      message: 'Organisation name must be at least 5 characters.',
    })
    .max(40, {
      message: 'Organisation name must not exceed 40 characters.',
    }),
});

function ChangeOrgName() {
  const { organisationName } = useAuth();
  const { refetchSubscription } = useSubscription();
  const [isEditing, setIsEditing] = useState(false);
  const updateOrgNameMutation = useUpdateOrgName();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      orgName: organisationName,
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    updateOrgNameMutation.mutate(data.orgName, {
      onSuccess: () => {
        setIsEditing(false);
        refetchSubscription();
      },
    });
  }

  const handleCancelEdit = () => {
    setIsEditing(false);
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="orgName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Change Organisation Name</FormLabel>
              <div className="flex items-center space-x-2">
                <FormControl className="flex-grow">
                  <Input
                    {...field}
                    disabled={!isEditing}
                    onFocus={() => setIsEditing(true)}
                  />
                </FormControl>
                {!isEditing ? (
                  <Button
                    type="button"
                    onClick={() => setIsEditing(true)}
                    variant="outline"
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button type="submit" className="gap-2">
                      Save <Check />
                    </Button>
                    <Button
                      onClick={handleCancelEdit}
                      type="button"
                      className="gap-2"
                      variant="outline"
                    >
                      Cancel <XIcon />
                    </Button>
                  </>
                )}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}

export default withPermissionGate(ChangeOrgName, ['org:update']);
