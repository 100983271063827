import { RefreshCw, Shield } from 'lucide-react';

interface LoadingProps {
  message?: string;
}

const Loading = ({ message = 'Loading...' }: LoadingProps) => {
  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <div className="relative">
        <Shield className="h-16 w-16 text-gray-400" />
        <RefreshCw className="animate-spin h-6 w-6 text-gray-400 absolute top-5 left-5" />
      </div>
      <p className="mt-4 text-gray-600">{message}</p>
    </div>
  );
};

export default Loading;
