import { Badge } from '@fluency/ui/components/ui/badge';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { AlertCircle, AlertTriangle, Check, Loader2 } from 'lucide-react';
import { forwardRef, useState } from 'react';
import { ExtensionStatusDialog } from './ExtensionStatusDialog';
import { useExtensionStatus } from './hooks/useExtensionStatus';
import { useSyncStatus } from './hooks/useSyncStatus';
import { StatusInfo } from './types';

const ExtensionStatusButton = forwardRef<HTMLDivElement>((_props, ref) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const { extensionStatus, checkExtensionStatus } = useExtensionStatus();
  const { syncStatus, sendAuthData } = useSyncStatus(
    extensionStatus,
    checkExtensionStatus
  );

  const handleButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleDownload = () => {
    window.open(
      'https://chromewebstore.google.com/detail/fluency/nhadgomabaphpfplmbfemfnagnhhghei',
      '_blank'
    );
  };

  const getStatusInfo = (): StatusInfo => {
    switch (extensionStatus) {
      case 'checking':
        return {
          text: 'Checking Extension...',
          icon: <Loader2 className="mr-2 h-4 w-4 animate-spin" />,
          customClass: 'text-gray-600 border-gray-300',
        };
      case 'not_installed':
        return {
          text: 'Extension not found',
          icon: <AlertTriangle className="mr-2 h-4 w-4" />,
          customClass: '',
        };
      case 'installed_not_connected':
        return {
          text: 'Sync Extension',
          icon: <AlertCircle className="mr-2 h-4 w-4" />,
          customClass:
            'bg-yellow-50 text-yellow-700 border-yellow-200 hover:bg-yellow-100',
        };
      case 'connected':
        return {
          text: 'Extension Connected',
          icon: <Check className="mr-2 h-4 w-4" />,
          customClass:
            'bg-blue-50 text-[#0770C0] border-[#0770C0] hover:bg-blue-100',
        };
    }
  };

  const { text, icon, customClass } = getStatusInfo();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <div className="relative inline-block w-full">
        <Badge
          ref={ref}
          className={`w-full mx-0 flex items-center text-xs justify-between pl-4 pr-2 py-2 mb-2 rounded-md cursor-pointer ${customClass} group h-10`}
          variant="outline"
          onClick={handleButtonClick}
        >
          <div className="flex items-center flex-grow">
            {icon}
            <span className="flex-grow group-hover:underline">{text}</span>
          </div>
        </Badge>
        {extensionStatus === 'not_installed' && (
          <div className="absolute -top-1.5 -right-1.5">
            <div className="bg-red-500 w-5 border-4 h-5 rounded-full border-white" />
          </div>
        )}
      </div>

      <ExtensionStatusDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        extensionStatus={extensionStatus}
        syncStatus={syncStatus}
        handleDownload={handleDownload}
        sendAuthData={sendAuthData}
      />
    </>
  );
});

ExtensionStatusButton.displayName = 'ExtensionStatusButton';

export default ExtensionStatusButton;
