import React, { ReactNode } from 'react';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { useNavigate } from '@tanstack/react-router';

interface RolePermissionHandlerProps {
  requiredPermissions: string[];
  children: ReactNode;
}

const log = (message: string, data?: any) => {
  if (
    import.meta.env.NODE_ENV === 'development' ||
    import.meta.env.NODE_ENV === 'staging'
  ) {
    console.log(`[RolePermissionHandler] ${message}`, data);
  }
};

export const RolePermissionHandler: React.FC<RolePermissionHandlerProps> = ({
  requiredPermissions,
  children,
}) => {
  const { hasPermissions } = usePermissionGate();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!hasPermissions(requiredPermissions)) {
      log('Required permissions: ', requiredPermissions);
      log('Insufficient permissions, navigating to unauthorized');
      navigate({ to: '/unauthorized', replace: true });
    }
  }, [hasPermissions, requiredPermissions, navigate]);

  return <>{children}</>;
};
