import React from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '../../providers/auth/AuthProvider.js';
import { ArrowLeft } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert.js';
import { Button } from '../ui/button.js';
import logo from '@fluency/ui/assets/icon-blue.svg';

export const NoSubscriptionMessage: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full bg-slate-100">
      <img src={logo} className="h-14 w-14 mb-6" alt="Fluency Logo" />
      <div className="bg-white rounded-lg border-2 p-8 max-w-md w-full">
        <h1 className="text-2xl font-bold mb-4">Welcome to Fluency! 🎉</h1>
        <Alert className="mb-6">
          <AlertTitle className="text-lg font-semibold">
            No Active Subscription
          </AlertTitle>
          <AlertDescription>
            Contact your admin or owner to purchase a subscription to continue.
          </AlertDescription>
        </Alert>
        <Button
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
          onClick={() => navigate({ to: '/subscription', replace: true })}
        >
          Go to Subscription Page
        </Button>
      </div>
      <Button
        variant="ghost"
        className="mt-6 flex items-center text-sm text-gray-600 hover:text-gray-800"
        onClick={async () => {
          console.log('Logging out and navigating to login');
          await logout();
          navigate({ to: '/login', replace: true });
        }}
      >
        <ArrowLeft className="w-4 h-4 mr-1" /> Sign in with a different account
      </Button>
    </div>
  );
};

export default NoSubscriptionMessage;
