import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { PermissionGateProvider } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { RefreshCw, PenBox } from 'lucide-react';
import UserTable from './UserTable/UserTable.js';
import InvitationsTable from './InviteTable/InvitationsTable.js';
import { useUpdateOrgName } from '../../hooks/index.js';
import { useState } from 'react';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';

export default function OrganisationCard() {
  const { isLoading, organisationName } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [newOrgName, setNewOrgName] = useState(organisationName);
  const updateOrgNameMutation = useUpdateOrgName();

  if (isLoading) {
    return (
      <div className="text-center flex justify-center items-center">
        <RefreshCw className="w-6 h-6 animate-spin text-gray-600" />
      </div>
    );
  }

  const handleUpdateOrgName = () => {
    updateOrgNameMutation.mutate(newOrgName, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  };

  return (
    <Card className="border-0">
      <CardHeader className="flex flex-row w-full justify-between pt-0 ">
        <div className="flex flex-col gap-1.5">
          {isEditing ? (
            <div className="flex items-center gap-2">
              <Input
                value={newOrgName}
                onChange={(e) => setNewOrgName(e.target.value)}
                className="w-64"
              />
              <Button
                onClick={handleUpdateOrgName}
                disabled={updateOrgNameMutation.isPending}
              >
                {updateOrgNameMutation.isPending ? 'Updating...' : 'Save'}
              </Button>
              <Button variant="ghost" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            </div>
          ) : (
            <CardTitle className="flex items-center gap-2">
              {organisationName}{' '}
              <PermissionGateProvider permissions={['org:update']}>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsEditing(true)}
                  className="text-gray-400 p-0"
                >
                  <PenBox className="w-5 h-5" />
                </Button>
              </PermissionGateProvider>
            </CardTitle>
          )}
          <CardDescription>
            Manage your organisation and its members.
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent>
        <UserTable />
        <PermissionGateProvider permissions={['org:update']}>
          <InvitationsTable />
        </PermissionGateProvider>
      </CardContent>
    </Card>
  );
}
