import { ColumnDef } from '@tanstack/react-table';
import { Badge } from '@fluency/ui/components/ui/badge';
import EditUserModal from './EditUserModal.js';
import { ArrowUpDown } from 'lucide-react';
import { PermissionGateProvider } from '@fluency/ui/providers/permissions/PermissionGateProvider';

export type Membership = {
  id: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
  };
  role: {
    slug: string;
  };
};

export const columns: ColumnDef<Membership>[] = [
  {
    accessorKey: 'user',
    header: ({ column }) => (
      <div
        className="flex items-center gap-2 hover:cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Members
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      const user = row.original.user;
      const displayName =
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.email;
      return <div className="flex items-center gap-2">{displayName}</div>;
    },
  },
  {
    accessorKey: 'user.email',
    header: ({ column }) => (
      <div
        className="flex items-center gap-2 hover:cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Email
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      const user = row.original.user;
      return (
        <div>
          {user.email}{' '}
          {user.emailVerified ? (
            <Badge variant="outline">Verified</Badge>
          ) : (
            <Badge variant="outline">Unverified</Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'role.slug',
    header: ({ column }) => (
      <div
        className="flex items-center gap-2 hover:cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Role
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.original.role.slug}</div>
    ),
  },
  {
    id: 'actions',
    cell: ({ row }) => (
      <div className="text-right items-end justify-end flex text-gray-500">
        {row.original.role.slug != 'owner' && (
          <PermissionGateProvider permissions={['org:update']}>
            <EditUserModal membership={row.original} />
          </PermissionGateProvider>
        )}
      </div>
    ),
  },
];
