import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { Shuffle } from 'lucide-react';
import useOrgInvites from '@fluency/ui/hooks/useOrgInvites';
import { useGetOrgUsers } from '@fluency/ui/hooks';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';

export default withPermissionGate(TransferOwnership, ['org:delete']);

function TransferOwnership() {
  const { transferOwnership } = useOrgInvites();
  const [open, setOpen] = useState(false);
  const [isConfirmingTransfer, setIsConfirmingTransfer] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);
  const { data } = useGetOrgUsers();

  const adminUsers =
    data?.memberships.filter((member) => member.role.slug === 'admin') || [];

  const handleTransferOwnership = async () => {
    if (isConfirmingTransfer && selectedAdmin) {
      setIsTransferring(true);
      try {
        await transferOwnership(selectedAdmin);
        toast({
          title: 'Success',
          description: 'Ownership transferred successfully.',
          variant: 'default',
        });
        setOpen(false);
      } catch (error) {
        toast({
          title: 'Error',
          description: `Failed to transfer ownership: ${
            error instanceof Error ? error.message : 'Unknown error'
          }`,
          variant: 'destructive',
        });
      } finally {
        setIsTransferring(false);
      }
    } else {
      setIsConfirmingTransfer(true);
    }
  };

  const cancelTransfer = () => {
    setIsConfirmingTransfer(false);
    setSelectedAdmin('');
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="link" className="w-fit text-red-600 gap-2 px-0">
          <Shuffle className="h-5 w-5 hover:cursor-pointer" /> Transfer
          Organisation Ownership
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogFooter>
          <div className="w-full">
            <span className="font-semibold text-red-600">
              Transfer ownership
            </span>
            <p className="text-sm text-red-600 mb-4">
              Transferring ownership will make this user the new owner of the
              organisation. You will lose your owner privileges and become an
              admin. This action cannot be undone.
            </p>
            {adminUsers.length === 0 ? (
              <div className="text-sm mb-4 font-semibold">
                <p className="mb-2">
                  There are no admins in the organisation to transfer ownership
                  to.
                </p>
                <p>Please add an admin before transferring ownership.</p>
              </div>
            ) : !isConfirmingTransfer ? (
              <>
                <Select onValueChange={setSelectedAdmin} value={selectedAdmin}>
                  <SelectTrigger className="w-full mb-4">
                    <SelectValue placeholder="Select an admin" />
                  </SelectTrigger>
                  <SelectContent>
                    {adminUsers.map((admin) => {
                      const displayName =
                        admin.user.firstName && admin.user.lastName
                          ? `${admin.user.firstName} ${admin.user.lastName}`
                          : admin.user.email;

                      return (
                        <SelectItem key={admin.id} value={admin.id}>
                          {displayName}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <Button
                  variant="destructive"
                  onClick={handleTransferOwnership}
                  className="w-full"
                  disabled={!selectedAdmin}
                >
                  Transfer Ownership
                </Button>
              </>
            ) : (
              <div className="flex gap-2">
                <Button
                  variant="destructive"
                  onClick={handleTransferOwnership}
                  className="flex-1"
                  disabled={isTransferring}
                >
                  {isTransferring ? 'Transferring...' : 'Confirm Transfer'}
                </Button>
                <Button
                  variant="outline"
                  onClick={cancelTransfer}
                  className="flex-1"
                  disabled={isTransferring}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
