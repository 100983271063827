import React, { useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@fluency/ui/components/ui/alert-dialog';
import WelcomeCard from './WelcomeCard';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@fluency/ui/components/ui/carousel';
import { Card, CardContent } from '@fluency/ui/components/ui/card';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import RecordImage from '@fluency/ui/assets/onboarding/record.png';
import ProcessImage from '@fluency/ui/assets/onboarding/process.png';
import DocumentationImage from '@fluency/ui/assets/onboarding/document.png';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';

interface WelcomeProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

interface StepCardProps {
  title: string;
  image: string;
}

const StepCard: React.FC<StepCardProps> = ({ title, image }) => (
  <CarouselItem>
    <div className="h-full flex items-center">
      <img className="w-full rounded-md h-fit" src={image} alt={title} />
    </div>
  </CarouselItem>
);

const Welcome: React.FC<WelcomeProps> = ({ isOpen, onOpenChange }) => {
  const { accessToken } = useAuth();
  const { isElectron } = useElectron();
  const steps = [
    {
      step: 1,
      title: 'Start Recording',
      description:
        'Name your process and add a description, then click "Record".',
      image: RecordImage,
    },
    {
      step: 2,
      title: 'Perform any process',
      description:
        'Fluency can record any process. Click "Stop Recording" when complete.',
      image: ProcessImage,
    },
    {
      step: 3,
      title: 'Boom',
      description:
        'Fluency outputs step-by-step documentation for your recorded process.',
      image: DocumentationImage,
    },
  ];

  useEffect(() => {
    const updateOnboardingStatus = async () => {
      if (!isOpen) return;

      try {
        const apiUrl = import.meta.env.VITE_SERVER_API_URL;
        const response = await fetch(
          `${apiUrl}/user/checkAndUpdateOnboardingStatus`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ onboardingComplete: true }),
          }
        );

        await response.json();
      } catch (error) {
        console.error('Error updating onboarding status:', error);
      }
    };

    updateOnboardingStatus();
  }, [isOpen, accessToken]);

  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent className="bg-transparent border-0 shadow-none flex flex-col justify-center w-full max-w-6xl items-center gap-0">
        <AlertDialogHeader>
          <AlertDialogTitle className="sr-only">
            Welcome to Fluency
          </AlertDialogTitle>
          <AlertDialogDescription className="sr-only">
            This dialog provides a step-by-step guide on how to use the
            application.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Carousel className="w-4/5">
          <CarouselContent>
            {steps.map((step, index) => (
              <StepCard key={index} {...step} />
            ))}
            {!isElectron && (
              <CarouselItem>
                <Card className="bg-transparent border-0">
                  <CardContent className="flex flex-col items-center justify-center gap-4">
                    <p className="text-gray-200  font-semibold text-lg">
                      Choose how you capture your processes:
                    </p>
                    <div className="flex justify-center gap-4 flex-col sm:flex-row items-center">
                      <WelcomeCard variant="create" />
                      <WelcomeCard variant="welcome" />
                    </div>
                  </CardContent>
                </Card>
              </CarouselItem>
            )}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
        <div className="w-full justify-center flex mt-4">
          <AlertDialogCancel className="bg-transparent text-white border-0 gap-2">
            Close
          </AlertDialogCancel>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Welcome;
